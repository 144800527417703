import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import { firebase_app } from "../../../data/config";

const PopUpCancel = (params) => {
  //   Fonction Paypal
  const [inProgress, setInProgress] = useState(false);

  // Parametre
  const { name, subscriptionID, dateSubscription } = params.value;
  const { onCancel } = params;
  // Send Element to DB
  const cancelAboPaypal = (params) => {
    const { subscriptionID, onCancel, dateSubscription } = params;
    setInProgress(true);

    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        var cancelSubscription = firebase_app
          .functions()
          .httpsCallable("cancelSubscription");

        cancelSubscription({
          UID: user.uid,
          subscriptionID: subscriptionID,
          dateSubscription: dateSubscription,
        })
          .then((r) => {
            console.log(r);
            setInProgress(false);
            onCancel();
            // Close Modal - passing props
          })
          .catch((e) => {
            console.log(e);
            setInProgress(false);
          });
      }
    });
  };

  // Load Spinner or not
  if (inProgress) {
    return (
      <div className="row justify-content-center">
        <div className="loader-box">
          <div className="loader">
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div className="badge-light border-2 f-12 b-r-2 p-2 b-light">
          <span>
            Vous êtes sur le point d'annuler l'abonnement{" "}
            <strong>{name}</strong>.
          </span>
        </div>
        <div className="p-3"></div>
        <div>
          <h6>
            <strong>Conditions d'annulation</strong>
          </h6>
          <span className="f-12">
            En procédant à l'annulation, le paiement recurrent sera annulé, vous
            pourrez encore profiter des services Qwantic pendant la période
            payée restante.
          </span>
        </div>
        <div className="p-3"></div>
        <div>
          <h6>
            <strong>Désactivation du robot </strong>
          </h6>
          <span className="f-12">
            Dans le cas d'une annulation, le bot reste actif jusqu'à la fin de
            la période payée restante. Pour désactiver immédiatement le bot,
            supprimer vos configurations dans l'onglet Trading Bot.
          </span>
        </div>
        <div className="p-3"></div>

        <Button
          color="secondary"
          onClick={() => {
            cancelAboPaypal({
              subscriptionID,
              dateSubscription,
              onCancel,
            });
          }}
        >
          {"Oui, j'annule mon abonnement"}
        </Button>
      </Fragment>
    );
  }
};

export default PopUpCancel;
