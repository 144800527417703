import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";

const ChoiceButton = ({ doSomethingAfterClick, value, buttons }) => {
  return (
    <ButtonGroup aria-label="Basic example">
      {buttons.map((button) => {
        return (
          <Button
            key={button.name}
            value={button.name}
            variant={
              value === button.name ? "primary" : "btn btn-outline-primary"
            }
            onClick={doSomethingAfterClick}
          >
            {button.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default ChoiceButton;
