import { firebase_app } from "../data/config";

export const postAPI = (value) => {
  const uid = firebase_app.auth().currentUser.uid;
  const { myAPIKey, mySecretKey, exchange, path } = value;
  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("API")
    .doc(path)
    .set({
      myAPIKey: myAPIKey,
      mySecretKey: mySecretKey,
      exchange: exchange,
    });
};

export const deleteAPI = (path) => {
  const uid = firebase_app.auth().currentUser.uid;
  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("API")
    .doc(path)
    .delete();
};

// Function pour obtenir tous les APIS enregistrés sur Firebase
export const getAllApisFirebase = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  const APIS = [];
  await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("API")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        APIS.push({ id: doc.id, ...doc.data() });
      });
    });
  return APIS;
};

export const creatTodoList = (value) => {
  firebase_app.firestore().collection("todo").add({
    task: value,
    completed: false,
  });
};

export const deleteList = (taskId) => {
  return firebase_app.firestore().collection("todo").doc(taskId).delete();
};

export const updateTask = (value) => {
  firebase_app.firestore().collection("todo").doc(value.id).set({
    task: value.task,
    completed: value.completed,
  });
};

export const markAllTask = (action) => {
  const db = firebase_app.firestore();
  db.collection("todo")
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        db.collection("todo")
          .doc(doc.id)
          .set({ task: doc.data().task, completed: action });
      });
    });
};
