import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
const ListBenefits = (value) => {
  const maxCapitalString = value.maxCapital
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const botsDCA = value.botsDCA;
  return (
    <ListGroup>
      <ListGroupItem className="list-group-item">
        <i className="fa fa-check-circle"></i>
        Jusqu'à <strong> {botsDCA} bots DCA</strong>
      </ListGroupItem>
      <ListGroupItem className="list-group-item">
        <i className="fa fa-check-circle"></i>
        {"Jusqu'à " + maxCapitalString + " USDT"}
      </ListGroupItem>
      <ListGroupItem className="list-group-item">
        <i className="fa fa-check-circle"></i>
        {"Sans engagement"}
      </ListGroupItem>
      <ListGroupItem className="list-group-item">
        <i className="fa fa-check-circle"></i>
        {"Aucun dépôt"}
      </ListGroupItem>
      <ListGroupItem className="list-group-item">
        <i className="fa fa-check-circle"></i>
        {"Compatible Binance"}
      </ListGroupItem>
      <ListGroupItem className="list-group-item">
        <i className="fa fa-check-circle"></i>
        {"Trading 24/7"}
      </ListGroupItem>
      <ListGroupItem className="list-group-item">
        <i className="fa fa-check-circle"></i>
        {"Choix levier et allocation"}
      </ListGroupItem>
    </ListGroup>
  );
};

export default ListBenefits;
