import React, { Component, Fragment } from "react";
import { Col, Card, CardBody } from "reactstrap";
import { firebase_app } from "../../../data/config";

class MetricsAmbassadeur extends Component {
  state = { nbrUser: 0, nbrUserActif: 0, sumAbo: 0, loading: true };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getMetrics(user);
      }
    });
  };

  getMetrics = (user) => {
    const getAffiliationMetrics = firebase_app
      .functions()
      .httpsCallable("getAffiliationMetrics");
    getAffiliationMetrics({ UID: user.uid }).then((result) => {
      this.setState({
        nbrUser: result.data.nbrUser,
        nbrUserActif: result.data.userActif,
        sumAbo: result.data.sumAbo,
        loading: false,
      });
    });
  };

  render() {
    return (
      <Fragment>
        <div className="row p-l-20 p-r-20">
          <Col xl="4" sm="4">
            <Card>
              <CardBody>
                <div className="project-widgets text-center">
                  <h3 className="font-success counter">
                    {this.state.loading ? "N/A" : this.state.nbrUser}
                  </h3>
                  <h6 className="mb-0">Utilisateur(s)</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" sm="4">
            <Card>
              <CardBody>
                <div className="project-widgets text-center">
                  <h3 className="font-success counter">
                    {this.state.loading ? "N/A" : this.state.nbrUserActif}
                  </h3>
                  <h6 className="mb-0">Actif(s)</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" sm="4">
            <Card>
              <CardBody>
                <div className="project-widgets text-center">
                  <h3 className="font-success counter">
                    {this.state.loading
                      ? "N/A"
                      : `${parseInt(this.state.sumAbo) / 10}€`}
                  </h3>
                  <h6 className="mb-0">Commission</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Fragment>
    );
  }
}

export default MetricsAmbassadeur;
