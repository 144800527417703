import { firebase_app } from "../data/config";

export const postBotQwantic = (value) => {
  const uid = firebase_app.auth().currentUser.uid;
  const { name, allocation, leverage, mode } = value;
  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("tradingBot")
    .set({
      name,
      allocation,
      leverage,
      mode,
    });
};

// Function pour obtenir tous les APIS enregistrés sur Firebase
export const getAllBotsFirebase = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  const bots = [];
  await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("tradingBot")
    .get()
    .then((snapshot) => bots.push(snapshot.data()));

  return bots;
};

export const deleteBot = () => {
  const uid = firebase_app.auth().currentUser.uid;
  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("tradingBot")
    .delete();
};
