import React, { Fragment } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import ChoiceButton from "./buttonselect";
import SweetAlert from "sweetalert2";
import Joi from "joi-browser";
import { postBotQwantic } from "../../../firebase-services/firebaseBotQwantic";
import { firebase_app } from "../../../data/config";

class FormQwanticTradingBot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "Qwantic Trading Bot",
      allocation: 50,
      leverage: 1,
      mode: "automatique",
      error: false,
      capital: 0,
      updateFirebase: true,
    };
  }

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        var getWalletLoggedUser = firebase_app
          .functions()
          .httpsCallable("getWalletLoggedUser");

        getWalletLoggedUser({ UID: user.uid })
          .then((r) => {
            this.setState({
              capital: r.data.capital,
              updateFirebase: false,
              error: r.data.error,
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };

  schema = {
    name: Joi.string()
      .max(64)
      .allow("", null)
      .empty()
      .default("Qwantic Trading Bot"),
    allocation: Joi.number()
      .integer()
      .min(0)
      .max(100)
      .required()
      .label("Allocation"),
    leverage: Joi.number()
      .integer()
      .min(1)
      .max(2)
      .required()
      .label("API Secret"),
    mode: Joi.string().required().label("Mode"),
  };

  handleSubmitQwanticBot = (event) => {
    event.preventDefault();
    SweetAlert.fire({
      title: "Good job!",
      text: "You clicked the button!",
      icon: "info",
    });
    const { name, allocation, leverage, mode } = this.state;

    const result = Joi.validate(
      { name, allocation, leverage, mode },
      this.schema,
      { abortEarly: false }
    );
    if (result.error) {
      SweetAlert.fire({
        title: "Erreur !",
        text: "Veuillez vérifier vos données",
        icon: "error",
      });
    } else {
      SweetAlert.fire({
        title: "Good job!",
        text: "Vos données sont en cours d'envoie",
        icon: "info",
      });
      postBotQwantic({
        allocation: this.state.allocation,
        leverage: this.state.leverage,
        mode: this.state.mode,
        name: this.state.name === "" ? "Default Bot Trading" : this.state.name,
      });
    }
  };

  handleChangeAllocation = (value) => {
    this.setState({
      allocation: value,
    });
  };

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleChangeLeverage = (event) => {
    this.setState({
      leverage: event.target.value,
    });
  };

  handleChangeMode = (event) => {
    this.setState({
      mode: event.target.value,
    });
  };

  render() {
    let { name, allocation, leverage, mode, capital, error, updateFirebase } =
      this.state;
    return (
      <Fragment>
        <form
          className="theme-form mega-form"
          onSubmit={this.handleSubmitQwanticBot}
        >
          <div className="card">
            <div className="card-header">
              <h5>
                <i className="fa fa-cog fa-1x"></i>
                {"    Configuration Qwantic Trading Bot"}
              </h5>
            </div>

            <div className="card-body">
              <h6>Cryptomonnaies</h6>
              <div className="row justify-content-center m-b-10 m-t-10">
                <img
                  src={`/assets/icons/btc.svg`}
                  alt={"Icon"}
                  className={"m-r-5"}
                  width={35}
                  height={35}
                />
                <img
                  src={`/assets/icons/ltc.svg`}
                  alt={"Icon"}
                  className={"m-r-5"}
                  width={35}
                  height={35}
                />
                <img
                  src={`/assets/icons/bnb.svg`}
                  alt={"Icon"}
                  className={"m-r-5"}
                  width={35}
                  height={35}
                />
                <img
                  src={`/assets/icons/ada.svg`}
                  alt={"Icon"}
                  className={"m-r-5"}
                  width={35}
                  height={35}
                />
                <img
                  src={`/assets/icons/xrp.svg`}
                  alt={"Icon"}
                  className={"m-r-5"}
                  width={35}
                  height={35}
                />
              </div>
              <div className="form-group">
                <h6>Nom</h6>
                <div className="row">
                  <div className="col-12">
                    <input
                      className="form-control"
                      type="namebot"
                      name="text"
                      value={name}
                      onChange={this.handleChangeName}
                      onClick={() => {
                        this.setState({ name: "" });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h6>Allocation</h6>
                <div className="row">
                  <div className="col-12">
                    <Slider
                      min={0}
                      max={100}
                      step={1}
                      labels={{ 0: "0%", 100: "100%" }}
                      format={(e) => e + "%"}
                      value={allocation}
                      orientation="horizontal"
                      onChange={this.handleChangeAllocation}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <h6>Choix du levier</h6>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <ChoiceButton
                    value={leverage}
                    doSomethingAfterClick={this.handleChangeLeverage}
                    buttons={[
                      { name: 1, label: "Levier 1" },
                      { name: 2, label: "Levier 2" },
                    ]}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <h6>Mode d'investissement</h6>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <ChoiceButton
                    value={mode}
                    doSomethingAfterClick={this.handleChangeMode}
                    buttons={[
                      // { name: "manuel", label: "Manuel" },
                      { name: "automatique", label: "Automatique" },
                    ]}
                  />
                </div>
              </div>

              <div className="row ">
                <span className="col">
                  <strong>Balance minimale requise</strong>
                </span>
                <div>
                  {260 / leverage},00 <strong>USDT</strong>
                </div>
              </div>

              <div className="row ">
                <span className="col">
                  <strong>Capital disponible </strong>
                </span>
                <div>
                  {updateFirebase || error
                    ? "N/A"
                    : parseInt(100 * capital) / 100}{" "}
                  <strong>{" USDT"}</strong>
                </div>
              </div>
              <div className="row ">
                <span className="col">
                  <strong>Capital alloué</strong>
                  {(capital * allocation * leverage) / 100 > 260 / leverage
                    ? ""
                    : " (insuffisant)"}
                </span>
                <div
                  className={
                    (capital * allocation * leverage) / 100 > 260 / leverage
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {updateFirebase || error
                    ? "N/A"
                    : parseInt(capital * allocation * leverage) / 100}{" "}
                  <strong>{" USDT"}</strong>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                  onClick={this.props.onSubmitBot}
                >
                  {"Soumettre"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default FormQwanticTradingBot;
