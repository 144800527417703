import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import { PayPalButton } from "react-paypal-button-v2";
import { firebase_app } from "../../../data/config";

const PopUpSub = (params) => {
  //   Fonction Paypal
  const [inProgress, setInProgress] = useState(false);

  // Parametre
  console.log(params);
  const { name, UID, price, idPaypal, quantity } = params.value;

  // Send Element to DB
  const sendPost = (params) => {
    const { orderID, subscriptionID, onPayment, name, quantity } = params;
    setInProgress(true);

    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        var sendDataPayments = firebase_app
          .functions()
          .httpsCallable("postPaymentsData");

        sendDataPayments({
          UID: user.uid,
          orderID: orderID,
          subscriptionID: subscriptionID,
          name: name,
          quantity: quantity,
        })
          .then((r) => {
            console.log(r);
            setInProgress(false);
            onPayment();
            // Close Modal - passing props
          })
          .catch((e) => {
            console.log(e);
            setInProgress(false);
          });
      }
    });
  };

  // Load Spinner or not
  if (inProgress) {
    return (
      <div className="row justify-content-center">
        <div className="loader-box">
          <div className="loader">
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div className="badge-light border-2 f-12 b-r-2 p-2 b-light">
          <span>
            Vous êtes sur le point d'activer l'abonnement{" "}
            <strong>{name}</strong> à <strong> {price}€ par mois. </strong>
          </span>
        </div>
        <div className="p-3"></div>
        <div>
          <h6>
            <strong>Conditions d'utilisation</strong>
          </h6>
          <span className="f-12">
            En procédant au paiement, vous acceptez nos{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://qwantic.io/cgu.html"
            >
              conditions générales d'utilisation
            </a>
            , notre{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://qwantic.io/privacy.html"
            >
              politique de vie privée{" "}
            </a>
            et notre{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://qwantic.io/disclamer.html"
            >
              clause de non-responsabilité
            </a>
          </span>
        </div>
        <div className="p-3"></div>
        <div>
          <h6>
            <strong>Rappel</strong>
          </h6>
          <span className="f-12">
            En procédant au paiement, vous acceptez la création d'un paiement
            récurrent mensuel et automatique. Ce paiement peut être annulé à
            tout moment. Le paiement est géré par la société Paypal.
          </span>
        </div>
        <div className="p-3"></div>

        <PayPalButton
          options={{
            vault: true,
            clientId:
              "AYQf5CsqFUs2q0FXGRLD2ORQzS8Ss4BfkTAI6el1SJM4YFIAfASjH9HMipJo5dy3WmK616mfiogIBzVt",
          }}
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: idPaypal, //plan_id
              custom_id: String(UID),
              quantity: quantity,
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
            });
          }}
          onApprove={(data, actions) => {
            // Capture the funds from the transaction
            return actions.subscription.get().then(function (details) {
              // Show a success message to your buyer
              alert("Subscription completed");

              // OPTIONAL: Call your server to save the subscription
              return sendPost({
                orderID: data.orderID,
                subscriptionID: data.subscriptionID,
                name,
                quantity,
                onPayment: params.onPayment,
              });
            });
          }}
        />
        <Button
          color="secondary"
          onClick={() => {
            sendPost({
              orderID: "test",
              subscriptionID: "I-545451",
              name,
              quantity,
              onPayment: params.onPayment,
            });
          }}
        >
          {"Test"}
        </Button>
      </Fragment>
    );
  }
};

export default PopUpSub;
