import React, { Fragment } from "react";
import Icofont from "react-icofont";

const ContactLink = () => {
  return (
    <Fragment>
      <div className=" text-center p-t-50 ">
        <a
          className="m-5"
          href="https://t.me/qwantic_community"
          target="_blank"
          style={{ color: "white" }}
        >
          <Icofont icon="telegram" size="2" />
        </a>
        <a
          className="m-5"
          href="https://www.facebook.com/qwantic"
          target="_blank"
          style={{ color: "white" }}
        >
          <Icofont icon="facebook" size="2" />
        </a>
        <a
          className="m-5"
          href="https://www.instagram.com/qwantic.io/"
          target="_blank"
          style={{ color: "white" }}
        >
          <Icofont icon="instagram" size="2" />
        </a>
      </div>
    </Fragment>
  );
};

export default ContactLink;
