import React, { Fragment } from "react";
import Swal from "sweetalert2";
import {
  getAllBotsFirebase,
  deleteBot,
} from "../../../firebase-services/firebaseBotQwantic";
import { firebase_app } from "../../../data/config";

class TableBot extends React.Component {
  state = { bots: [], update: this.props.value, delete: 0 };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.value !== this.props.value ||
      prevState.delete !== this.state.delete
    ) {
      console.log("Update");
      firebase_app.auth().onAuthStateChanged((user) => {
        if (user) {
          this.getBots();
        }
      });
    }
  }

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getBots();
      }
    });
  };

  getBots = () => {
    getAllBotsFirebase().then((result) => {
      console.log(result[0]);
      if (result[0] !== undefined) {
        let bots = result.map((bot) => {
          const tmp = {
            name: bot.name,
            leverage: bot.leverage,
            allocation: bot.allocation,
            mode: bot.mode,
          };
          return tmp;
        });

        this.setState({ bots });
      } else {
        this.setState({ bots: [] });
      }
    });
  };

  handleDelete = (path) => {
    Swal.fire({
      title: "Supprimer le bot ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.value) {
        Swal.fire({ title: "Supprimé!", icon: "success" });
        deleteBot(path);
        this.setState({ delete: this.state.delete + 1 });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="card-header">
            <h5>{"Mes Bots"}</h5>

            {this.state.bots.length === 0 ? (
              <span>
                {"Aucun bot n'est configuré - "}
                <a href="/bot/qwantic-trading-bot">{"Ajouter mon bot"}</a>
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">{"#"}</th> */}

                  <th scope="col">{"Name"}</th>
                  <th scope="col">{"Allocation"}</th>
                  <th scope="col">{"Levier"}</th>
                  {/* <th scope="col">{"Mode"}</th> */}
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.bots.map((bot) => {
                  return (
                    <tr key={bot.name}>
                      {/* <th scope="row">{"1"}</th> */}
                      <th>{bot.name}</th>

                      <td>{`${bot.allocation} %`}</td>
                      <td>{bot.leverage}</td>
                      {/* <td>{bot.mode}</td> */}
                      <td>
                        <button
                          onClick={() => this.handleDelete(bot.name)}
                          className="btn btn-danger"
                        >
                          <strong>X</strong>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TableBot;
