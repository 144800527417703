import React, { Fragment, useState } from "react";
import { firebase_app } from "../../../data/config";

const UserPanel = () => {
  const [name, setName] = useState("");

  const getName = () => {
    if (name !== "") {
      return 0;
    }
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        setName(user.email);
      }
    });
  };
  getName();
  return (
    <Fragment>
      <div className="sidebar-user text-center">
        {/* <div>
          <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" />
          <div className="profile-edit">
            <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
              <Edit />
            </Link>
          </div>
        </div> */}
        <h6 className="mt-3 f-14">{"Bienvenue"}</h6>
        <p>{name}</p>
      </div>
    </Fragment>
  );
};

export default UserPanel;
