import React, { Component, Fragment } from "react";

class SmartFilter extends Component {
  state = { name: "" };

  handleChangeFilter = (e) => {
    this.props.onChangeFilter(e.target.value);
  };

  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="row">
            <div className="col-sm-12 col-xl-5">
              <input
                className="form-control"
                onChange={this.handleChangeFilter}
                placeholder="Rechercher symbol (ex: BTC, Bitcoin)"
                ref={(el) => (this.inputTitle = el)}
              ></input>
              <button
                className="btn btn-danger m-10"
                onClick={() => {
                  this.inputTitle.value = "";
                  this.props.onChangeRadioFilter(0);
                }}
              >
                Reset Filtre
              </button>
            </div>

            {/* <div className="col-sm-12 col-xl-5">
              <label className="d-block" htmlFor="edo-ani">
                <input
                  className="radio_animated"
                  id="edo-ani"
                  type="radio"
                  name="rdo-ani"
                  onChange={() => {
                    this.props.onChangeRadioFilter(1);
                  }}
                />
                {"Top 10 - Plus grande capitalisation"}
              </label>
              <label className="d-block" htmlFor="edo-ani1">
                <input
                  className="radio_animated"
                  id="edo-ani1"
                  type="radio"
                  name="rdo-ani"
                  onChange={() => {
                    this.props.onChangeRadioFilter(2);
                  }}
                />
                {"Plus grande hausse sur 24h"}
              </label>
            </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SmartFilter;
