import React, { Fragment } from "react";
import { deleteBotDCA } from "../../../firebase-services/firebaseDCA";

class TableDCA extends React.Component {
  state = {
    bots: [],
    // botsBrut: this.props.bots,
    update: this.props.value,
    delete: 0,
    loading: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.bots !== this.props.bots
    ) {
      const bots = this.sortBot(this.props.bots);
      this.setState({ bots, loading: false });
    }
  }

  // componentDidMount = () => {
  //   const bots = this.sortBot(this.state.botsBrut);

  //   this.setState({ bots });
  // };

  sortBot = (botsBrut) => {
    var sortDCA = [];
    for (let k in botsBrut) {
      sortDCA.push({
        symbol: k,
        executed: botsBrut[k]["executed"],
        frequency: botsBrut[k]["frequency"],
        limitTime: botsBrut[k]["limitTime"],
        allocation: botsBrut[k]["allocation"],
        occurence: botsBrut[k]["occurence"],
      });
    }
    return sortDCA;
  };

  handleDelete = (symbol) => {
    deleteBotDCA(symbol);
    var tmpBot = [...this.state.bots];
    tmpBot = tmpBot.filter((c) => c.symbol !== symbol);
    this.setState({ bots: tmpBot });
  };

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <div className="card">
            <div className="card-header">
              <h5>{"Mes Bots DCA"}</h5>
              <div className="row justify-content-center">
                <div className="loader-box">
                  <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="card">
            <div className="card-header">
              <h5>{"Mes Bots DCA"}</h5>

              {this.state.bots.length === 0 ? (
                <span>
                  {"Aucun bot DCA n'est configuré - "}
                  <a href="/bot/dca">{"Ajouter mon bot"}</a>
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th scope="col">{"#"}</th> */}

                    <th scope="col">{"Symbole"}</th>
                    <th scope="col">{"Allocation (USDT)"}</th>
                    <th scope="col">{"Fréquence"}</th>
                    <th scope="col">{"Transactions"}</th>
                    <th scope="col">{"Supprimer ?"}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.bots.map((bot) => {
                    return (
                      <tr key={bot.symbol}>
                        {/* <th scope="row">{"1"}</th> */}
                        <th>{bot.symbol}/USDT</th>
                        <td>{`${bot.allocation} USDT`} </td>
                        <td>
                          {`${bot.occurence}`}
                          <sub>/{bot.frequency}</sub>
                        </td>
                        <td>{`${bot.executed}/${bot.limitTime} (${parseInt(
                          (100 * bot.executed) / bot.limitTime
                        )}%)`}</td>

                        <td>
                          <button
                            className="btn btn-danger btn-xs m-l-20"
                            onClick={() => this.handleDelete(bot.symbol)}
                          >
                            <strong>X</strong>
                            {/* <i className="fa fa-minus"></i> */}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default TableDCA;
