import React, { Component, Fragment } from "react";
import { getLogDCA } from "../../../firebase-services/firebaseDCA";
import DataTable from "react-data-table-component";
import { firebase_app } from "../../../data/config";
import _ from "lodash";
import { deleteAllLogDCA } from "../../../firebase-services/firebaseDCA";

class LogDCA extends Component {
  state = { log: [], bilanDCA: [], update: 0 };

  timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    year = parseInt(year) < 10 ? `0${year}` : year;
    month = parseInt(month) < 10 ? `0${month}` : month;
    date = parseInt(date) < 10 ? `0${date}` : date;
    hour = parseInt(hour) < 10 ? `0${hour}` : hour;
    min = parseInt(min) < 10 ? `0${min}` : min;
    sec = parseInt(sec) < 10 ? `0${sec}` : sec;
    var time =
      date + "/" + month + "/" + year + " " + hour + ":" + min + ":" + sec;
    return time;
  };
  format_output = (output) => {
    var n = Math.log(output) / Math.LN10;
    var x = 4 - n;
    if (x < 0) x = 0;
    output = output.toFixed(x);
    return output;
  };
  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getLogDCA().then((res) => {
          // console.log(res);

          const log = _.map(res, (element, key) => {
            return {
              amount: element.amount,
              price: element.price,
              time: this.timeConverter(parseInt(key)),
              key: key,
              quantity: element.quant,
              symbol: element.symbol,
              valorisation: 0,
              keys: [key],
            };
          });

          // Regroupement

          var bilanDCA = log.map((a) => ({ ...a }));
          for (var i = 0; i < bilanDCA.length - 1; i++) {
            for (var j = i + 1; j < bilanDCA.length; j++) {
              if (bilanDCA[i].symbol === bilanDCA[j].symbol) {
                bilanDCA[i].amount = bilanDCA[i].amount + bilanDCA[j].amount;
                bilanDCA[i].quantity =
                  bilanDCA[i].quantity + bilanDCA[j].quantity;
                bilanDCA[j].amount = 0;
                bilanDCA[i].keys.push(bilanDCA[j].key);
              }
            }
          }
          // Filter

          bilanDCA = bilanDCA.filter((crypto) => crypto.amount !== 0);
          bilanDCA.map(
            (crypto) =>
              (crypto.price = this.format_output(
                crypto.amount / crypto.quantity
              ))
          );
          console.log(log);
          this.setState({ log: log.reverse(), bilanDCAtmp: bilanDCA });
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data !== this.props.data ||
      prevState.update !== this.state.update
    ) {
      var tmpBilanDCA = [...this.state.bilanDCAtmp];
      tmpBilanDCA.map((cryptoDCA) => {
        for (var i = 0; i < this.props.data.length; i++) {
          if (this.props.data[i].symbol === cryptoDCA.symbol) {
            cryptoDCA.symbol = `${this.props.data[i].name} (${cryptoDCA.symbol})`;
            cryptoDCA.valorisation =
              parseInt(
                (1000 * (this.props.data[i].price - cryptoDCA.price)) /
                  cryptoDCA.price
              ) / 10;
            return cryptoDCA;
          }
        }
      });

      this.setState({ bilanDCA: tmpBilanDCA });
    }
  }

  columnsDCA = [
    {
      name: "Symbole",
      selector: "symbol",
      sortable: true,
      center: true,
    },

    {
      name: "Prix de revient unitaire (PRU)",
      selector: "price",
      sortable: true,
      center: true,
      cell: (row) => {
        return <div>{`${row.price} $`}</div>;
      },
    },

    {
      name: "Performance (%)",
      selector: "valorisation",
      sortable: true,
      center: true,
      cell: (row) => {
        return (
          <div
            className={row.valorisation >= 0 ? "font-success" : "font-danger"}
          >
            <i
              className={
                row.valorisation >= 0
                  ? "fa fa-long-arrow-up"
                  : "fa fa-long-arrow-down"
              }
            ></i>
            {` ${row.valorisation}%`}
          </div>
        );
      },
    },

    {
      name: "Total investi",
      selector: "amount",
      sortable: true,
      center: true,
      cell: (row) => {
        return <div>{`${parseInt(100 * row.amount) / 100} $`}</div>;
      },
    },

    {
      name: "Action",
      selector: "time",
      sortable: true,
      center: true,
      cell: (row) => {
        return (
          <button
            className="btn btn-danger btn-xs m-l-20"
            onClick={() => {
              deleteAllLogDCA(row.keys);
              var tmpBilanDCA = [...this.state.bilanDCA];
              tmpBilanDCA = tmpBilanDCA.filter(
                (crypto) => crypto.symbol !== "test"
              );

              this.setState({
                bilanDCA: tmpBilanDCA,
              });
            }}
          >
            <strong>X</strong>
          </button>
        );
      },
    },
  ];
  columns = [
    {
      name: "Time",
      selector: "time",
      sortable: true,
      center: true,
    },
    {
      name: "Symbole",
      selector: "symbol",
      sortable: true,
      center: true,
    },

    {
      name: "Quantité",
      selector: "quantity",
      sortable: true,
      center: true,
    },
    {
      name: "Montant",
      selector: "amount",
      sortable: true,
      center: true,
      cell: (row) => {
        return <div>{`${row.amount} $`}</div>;
      },
    },
    {
      name: "Prix d'acquisition",
      selector: "price",
      sortable: true,
      center: true,
      cell: (row) => {
        return <div>{`${row.price} $`}</div>;
      },
    },
    {
      name: "Action",
      selector: "time",
      sortable: true,
      center: true,
      cell: (row) => {
        return (
          <button
            className="btn btn-danger btn-xs m-l-20"
            onClick={() => {
              deleteAllLogDCA([row.key]).then(() => {
                this.componentDidMount();
              });
            }}
          >
            <strong>X</strong>
          </button>
        );
      },
    },
  ];

  render() {
    return (
      <Fragment>
        {" "}
        <div className="datatable-react">
          <div className="card">
            <div className="card-header">
              <h6>Bilan DCA</h6>
            </div>
            <DataTable
              columns={this.columnsDCA}
              data={this.state.bilanDCA}
              // subHeaderComponent={this.subHeaderComponentMemo}
              noDataComponent="Aucun historique disponible"
              dense={true}
              subHeader={false}
              noHeader={true}
            />
          </div>
          <div className="card">
            <div className="card-header">
              <h6>Ordres passées</h6>
            </div>

            <DataTable
              columns={this.columns}
              data={this.state.log}
              pagination
              // subHeaderComponent={this.subHeaderComponentMemo}
              noDataComponent="Aucun historique disponible"
              dense={true}
              subHeader={false}
              noHeader={true}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LogDCA;
