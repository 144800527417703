import React, { Fragment } from "react";
import Slider from "react-slick";
import { ListGroup, ListGroupItem } from "reactstrap";
import ChoiceButton from "../qwantic-trading-bot/buttonselect";
import tuto_1 from "../../../../src/assets/images/tuto/tuto_api_1.png";
import tuto_2 from "../../../../src/assets/images/tuto/tuto_api_2.png";

class UsefullLinkAPI extends React.Component {
  state = {
    valeurcle: "1",
  };

  lazyload_settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  handleChangeLeverage = (event) => {
    this.setState({
      valeurcle: event.target.value,
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card p-b-10">
          <div className="card-header b-l-warning">
            <h5>Comment connecter sa clé API</h5>
            <span>Binance</span>
          </div>
          <div className="card-body">
            <Slider {...this.lazyload_settings}>
              <div className="card p-l-30 ">
                <h6>1. Sélectionner le menu "Gestion API"</h6>
                <div className="card-body ">
                  <img className="img-fluid " src={tuto_1} alt="" />
                  <span>
                    Dans le menu en haut à droite, cliquez sur le compte.
                    <br></br>Accessible au lien suivant:{" "}
                    <a href="/1">
                      <i className="fa fa-external-link "></i> {"Page API"}
                    </a>
                  </span>
                </div>
              </div>
              <div className="card p-l-30">
                <h6>2. Générer une nouvelle clé</h6>
                <div className="card-body">
                  <img className="img-fluid" src={tuto_2} alt=""></img>
                  <span>
                    Entrez le nom que vous souhaitez, puis cliquez sur "API
                    créée". Par exemple: 'Qwantic API'
                  </span>
                </div>
              </div>
              <div className="card p-l-20">
                <h6>3. Paramétrer sa clé Binance</h6>

                <div className="row justify-content-center">
                  <ChoiceButton
                    value={this.state.valeurcle}
                    doSomethingAfterClick={this.handleChangeLeverage}
                    buttons={[
                      { name: "1", label: "Spot" },
                      { name: "2", label: "Futures" },
                    ]}
                  />
                </div>

                <div key={this.state.valeurcle} className="card-body">
                  Les paramètres importants à cocher:
                  <ListGroup>
                    <ListGroupItem
                      className="list-group-item-action"
                      tag="a"
                      href="#javascript"
                    >
                      <i className="icon-check-box"></i>
                      {"Permettre la lecture"}
                    </ListGroupItem>
                    <ListGroupItem
                      className="list-group-item-action"
                      tag="a"
                      href="#javascript"
                    >
                      <i className="icon-check-box"></i>
                      {this.state.valeurcle === "1"
                        ? "Activer le trading au comptant et sur marge"
                        : "Activer contrat à terme"}
                    </ListGroupItem>
                  </ListGroup>
                  <br></br>Les paramètres importants à décocher:
                  <ListGroup>
                    <ListGroupItem
                      className="list-group-item-action"
                      tag="a"
                      href="#javascript"
                    >
                      <i className="icon-na"></i>
                      {"Activer les retraits"}
                    </ListGroupItem>
                    <ListGroupItem
                      className="list-group-item-action"
                      tag="a"
                      href="#javascript"
                    >
                      <i className="icon-na"></i>
                      {this.state.valeurcle === "1"
                        ? "Activer contrat à terme"
                        : "Activer le trading au comptant et sur marge"}
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UsefullLinkAPI;
