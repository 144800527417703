import { getFaq, getFaqTitle } from "./faqList";
import React, { Fragment } from "react";
import { HelpCircle } from "react-feather";
import { Collapse } from "reactstrap";

class FaqRender extends React.Component {
  state = {
    listFaqs: getFaq(this.props.theme),
    listFaqsTitle: getFaqTitle(this.props.theme),
  };

  setIsCollaps = (collapse) => {
    let listFaqstemp = this.state.listFaqs;
    listFaqstemp[collapse]["stateCollapse"] =
      !listFaqstemp[collapse]["stateCollapse"];
    this.setState({ listFaqs: listFaqstemp });
  };

  render() {
    return (
      <Fragment>
        <div className="faq-title">
          <h6>{this.state.listFaqsTitle}</h6>
        </div>

        {this.state.listFaqs.map((listfaq) => (
          <div key={listfaq.collapse} className="card">
            <div className="card-header">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed pl-0"
                  onClick={() => this.setIsCollaps(listfaq.collapse)}
                  data-toggle="collapse"
                  data-target="#collapseicon"
                  aria-expanded={listfaq.stateCollapse}
                  aria-controls="collapseicon"
                >
                  <HelpCircle />
                  {listfaq.title}
                </button>
              </h5>
            </div>
            <Collapse isOpen={listfaq.stateCollapse}>
              <div className="card-body">{listfaq.answer}</div>
            </Collapse>
          </div>
        ))}
      </Fragment>
    );
  }
}

export default FaqRender;
