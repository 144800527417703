import { firebase_app } from "../data/config";

// Function pour obtenir tous les APIS enregistrés sur Firebase
export const getAllPerformanceFutures = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  const snapshot = await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("performancesTradingBot")
    .get();
  var labels = [];
  var dataPlot = [];
  var flux = [];
  const dataTime = [];
  snapshot.forEach((doc) => {
    var stringDate = doc.data().time_str;
    labels.push(stringDate.substring(0, 6) + stringDate.substring(8, 10));
    dataPlot.push(parseInt(doc.data().capital * 100) / 100);
    flux.push(parseInt(doc.data().flux * 100) / 100);
    dataTime.push(parseInt(doc.data().time));
  });

  if (dataTime[dataTime.length - 1] > dataTime[dataTime.length]) {
    labels.reverse();
    dataPlot.reverse();
    flux.reverse();
  }

  //////////// CALCUL TWR
  var TWR24h = "N/A";
  var TWR1m = "N/A";
  var TWR1w = "N/A";
  var TWRTot = "N/A";
  var R = [];
  var TWRPlot = [];
  if (dataPlot.length >= 2) {
    for (let i = 0; i < dataPlot.length; i++) {
      if (i === !0) {
        R.push(0);
      } else {
        var flux_0 = flux[i];
        var capital_0 = dataPlot[i];
        var capital_1 = dataPlot[i - 1];
        // 2* flux car le flux est déjà dans le capital_0 lors de la mesure
        if (capital_1 > 0) {
          R.push((capital_0 - capital_1 - 1 * flux_0) / capital_1);
        } else {
          R.push(0);
        }
      }
    }

    var TWR = 1;
    for (let i = 0; i < R.length; i++) {
      TWR = TWR * (1 + R[i]);
      TWRPlot.push(parseInt(100 * 100 * (TWR - 1)) / 100);
    }

    ////////////

    // var finalCap = 1;
    // var initialCap = 1;

    if (R.length >= 2) {
      // Performance totale :
      TWR = TWRPlot[TWRPlot.length - 1];
      TWRTot = (TWR > 0 ? "+" : "") + String(Math.round(100 * TWR) / 100) + "%";
      // Performance 24h glissante
      var longueur = 4;
      if (R.length < 4) {
        longueur = R.length - 1;
      }
      TWR24h = 1;
      for (let i = R.length - longueur; i < R.length; i++) {
        TWR24h = TWR24h * (1 + R[i]);
      }
      TWR24h = TWR24h - 1;

      TWR24h =
        (TWR24h > 0 ? "+" : "") +
        String(Math.round(100 * 100 * TWR24h) / 100) +
        "%";

      // performance 7j

      longueur = 4 * 7;
      if (R.length < 4 * 7) {
        longueur = R.length - 1;
      }
      TWR1w = 1;
      for (let i = R.length - longueur; i < R.length; i++) {
        TWR1w = TWR1w * (1 + R[i]);
      }
      TWR1w = TWR1w - 1;
      TWR1w =
        (TWR1w > 0 ? "+" : "") +
        String(Math.round(100 * 100 * TWR1w) / 100) +
        "%";

      // Performance 1m
      longueur = 4 * 7 * 4;
      if (R.length < 4 * 7 * 4) {
        longueur = R.length - 1;
      }
      TWR1m = 1;
      for (let i = R.length - longueur; i < R.length; i++) {
        TWR1m = TWR1m * (1 + R[i]);
      }
      TWR1m = TWR1m - 1;
      TWR1m =
        (TWR1m > 0 ? "+" : "") +
        String(Math.round(100 * 100 * TWR1m) / 100) +
        "%";
    }
  }
  // Plot Flux :
  var fluxPlotDepot = [];

  for (let i = 0; i < dataPlot.length; i++) {
    if (i === 0) {
      fluxPlotDepot.push(null);
    } else {
      if (parseInt(flux[i]) > 0) {
        fluxPlotDepot.push(dataPlot[i]);
      } else {
        fluxPlotDepot.push(null);
      }
    }
  }
  var fluxPlotRetrait = [];

  for (let i = 0; i < dataPlot.length; i++) {
    if (i === 0) {
      fluxPlotRetrait.push(null);
    } else {
      if (parseInt(flux[i]) < 0) {
        fluxPlotRetrait.push(dataPlot[i]);
      } else {
        fluxPlotRetrait.push(null);
      }
    }
  }

  const limitSize = 45;
  if (dataPlot.length >= limitSize * 4) {
    dataPlot = dataPlot.slice(-limitSize * 4 + 1);
    labels = labels.slice(-limitSize * 4 + 1);
    TWRPlot = TWRPlot.slice(-limitSize * 4 + 1);
    fluxPlotRetrait = fluxPlotRetrait.slice(-limitSize * 4 + 1);
    fluxPlotDepot = fluxPlotDepot.slice(-limitSize * 4 + 1);
  }

  let labels2 = [];
  let dataPlot2 = [];
  let TWRPlot2 = [];
  // 1 point by day
  if (labels.length > 2) {
    for (let i = 1; i < labels.length - 1; i++) {
      if (labels[i - 1] !== labels[i]) {
        labels2.push(labels[i]);
        dataPlot2.push(dataPlot[i]);
        TWRPlot2.push(TWRPlot[i]);
      }
    }
    labels2.push(labels[labels.length - 1]);
    dataPlot2.push(dataPlot[dataPlot.length - 1]);
    TWRPlot2.push(TWRPlot[TWRPlot.length - 1]);
  } else {
    labels2 = labels;
    dataPlot2 = dataPlot;
    TWRPlot2 = TWRPlot;
  }

  labels = labels2;
  dataPlot = dataPlot2;
  TWRPlot = TWRPlot2;

  return {
    labels,
    dataPlot,
    fluxPlotDepot,
    fluxPlotRetrait,
    TWR24h,
    TWR1w,
    TWR1m,
    TWRPlot,
    TWRTot,
  };
};
