import React, { Fragment } from "react";
import MetricsAmbassadeur from "./metricsAmbassadeur";

class AmbassadeurComponent extends React.Component {
  state = { textButton: "Copier le lien" };
  //   componentDidMount = () => {
  //     firebase_app.auth().onAuthStateChanged((user) => {
  //       if (user) {
  //         getAffiliationInfo(user.uid).then((res) => {
  //           this.setState({
  //             isAmbassadeur: res.isAmbassadeur,
  //             affiliateID: res.affiliateID,
  //           });

  //           getParrainageInfo(user.uid).then((res) => {
  //             console.log(res);
  //             this.setState({
  //               IDparrainage: res.IDparrainage,
  //               avantageParrain: res.avantageParrain,
  //             });
  //           });
  //         });
  //       }
  //     });
  //   };
  changeText = (textButton) => {
    this.setState({ textButton });
  };

  render() {
    return (
      <Fragment>
        <div className="container row justify-content-center">
          <div className="card col-sm-12 col-xl-8">
            <div className="card-header">
              <h5>Programme Ambassadeur</h5>
            </div>
            <div className="card-body">
              <MetricsAmbassadeur />
              <h6>Augmentez votre revenu grâce à votre communauté</h6>
              <span>
                Vous êtes actuellement membre du programme
                <strong> Ambassadeur de Qwantic</strong>, ce programme vous
                permet de soutenir le projet Qwantic tout étant rémunéré selon
                le tableau ci-dessous.
              </span>
              <div className="m-25"></div>
              <div className="table-responsive row justify-content-center ">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">{"Total abonnement"}</th>
                      <th scope="col">{"<500€"}</th>
                      <th scope="col">{"10,000€"}</th>
                      <th scope="col">{"15,000€"}</th>
                      <th scope="col">{"+15,000€"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{"Pourcentage de commission"}</th>
                      <td>{"0%"}</td>
                      <td>{"10%"}</td>
                      <td>{"10%"}</td>
                      <td>{"10%"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="m-25"></div>
              <span>
                Exemple : 50 personnes avec un abonnement à 50€ sont liées à
                votre programme, pour un total de 2,500€ d'abonnement. Votre
                pourcentage de commission s'élève à 10% (cas 10,000€), soit 250€
                de commission.
              </span>
              <div className="m-50"></div>
              <h6>Mon Lien Amabassadeur</h6>
              <span>
                Pour partager votre programme auprès de votre communauté, il
                vous suffit simplement de partager le lien ci-dessous
              </span>
              <div className="m-50"></div>
              <div className="col-12">
                <div className="col justify-content-center">
                  <h6
                    className="font-primary f-s-italic o-visible"
                    style={{ textAlign: "center" }}
                  >{`https://platform.qwantic.io/ambassadeur/${this.props.affiliateID}`}</h6>
                </div>

                <div className="row justify-content-center">
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://platform.qwantic.io/ambassadeur/${this.props.affiliateID}`
                      );
                      this.changeText("Copié !");
                    }}
                    className="btn btn-outline-primary btn-block col-xl-3"
                  >
                    {this.state.textButton}
                  </button>
                </div>
              </div>

              <div className="m-50"></div>
              <h6>Conditions Générales</h6>

              <ul>
                <li className="m-10">
                  - Chaque demande d'ambassadeur sera traitée dans les plus
                  brefs délais. Soyez patient, nos bureaux traiteront toutes les
                  demandes. Lorsque vous aurez été accepté en tant
                  qu'ambassadeur, vous recevrez un lien d'affiliation que vous
                  pourrez partager avec votre communauté.
                </li>
                <li className="m-10">
                  - Être un ambassadeur Qwantic signifie que vous recommandez à
                  de nouvelles personnes les abonnements Carbon, Gold ou
                  Uranium, en utilisant votre lien d'affiliation personnel. La
                  commission sera calculé uniquement sur base d'utilisateur
                  parrainé actif (comprendre utilisateur payant de Qwantic au
                  moment du calcul des commissions)
                </li>

                <li className="m-10">
                  - La diffusion d'attitudes/messages négatifs à propos de
                  Qwantic risquera votre suppression en tant qu'ambassadeur. Si
                  vous avez choisis de promouvoir Qwantic c'est que vous êtes
                  convaincu par la solution. Laissez votre attitude positive
                  parler! <span role="img">👍</span>
                </li>
                <li className="m-10">
                  - La personne que vous recrutez ne peut avoir déjà un compte
                  chez Qwantic.
                </li>
                <li className="m-10">
                  - Vous devez être titulaire d'un compte Gold ou supérieur pour
                  rejoindre le programme Ambassadeur. Nous estimons que pour
                  mettre en avant la solution, vous devez l'avoir déjà utilisé.
                </li>
                <li className="m-10">
                  - Qwantic se réserve le droit de mettre fin au programme
                  ambassadeur sans en justifier la raison
                </li>
              </ul>
              <div className="m-50"></div>
              <h6>Conditions de paiement</h6>
              <span>
                <ul>
                  <li className="m-10">
                    - Les paiements sont effectués en début de chaque mois.
                  </li>
                  <li className="m-10">
                    - L'ambassadeur doit justifier d'un minimum de 50€ de
                    commission pour être rémunéré
                  </li>
                  <li className="m-10">
                    - Les commissions ne peuvent pas s'additionner sur plusieurs
                    mois afin de remplir la condition précédente.
                  </li>
                  <li className="m-10">
                    - Lorsque vos commissions mensuelles d'affiliation tombent
                    en dessous des montants mentionnés ci-dessus, vous serez
                    déclassé en conséquence. Ce pourcentage est automatiquement
                    vérifié et ajusté chaque mois.
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AmbassadeurComponent;
