import React, { useState, Fragment } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const DefinitionListing = () => {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h5>Introduction</h5>
        </CardHeader>
        <CardBody>
          <div className="tabbed-card">
            <Nav className="pull-right  nav-pills nav-primary">
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                >
                  {"Définition"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                >
                  {"Fonctionnement"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => setActiveTab("3")}
                >
                  {"Performances"}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p className="mb-0">
                  Le <strong>New Listing Bot</strong> est une nouvelle approche
                  développée par Qwantic, permettant d'acheter les nouvelles
                  paires dès leur introduction sur la plateforme Binance. Les
                  introductions sont fréquentes mais les dates ne sont pas
                  communiquées à l'avance.{" "}
                  <strong>Procurez vous un avantage</strong> en étant l'un des
                  premiers à se placer sur une paire. Le bot ne ferme pas la
                  position, ainsi il est de la reponsabilité de l'utilisateur de
                  clôturer sa position.
                </p>
              </TabPane>
              <TabPane tabId="2">
                <p className="mb-0">
                  Notre bot analyse plusieurs fois par minute les paires
                  disponibles en Spot sur Binance et, dès l'introduction d'une
                  nouvelle paire, et permet à l'utilsateur de l'accumuler dans
                  son portefeuille Binance. La bot se focalise uniquement sur
                  des paires en Spot ayant pour base l'USDT (ex : BTCUSDT).
                  Qwantic vous permet de définir un capital maximal alloué à
                  cette approche.
                </p>
              </TabPane>
              <TabPane tabId="3">
                <p className="mb-0">
                  Aucune indication de performance ne peut être communiquée,
                  néanmoins, les nouvelles paires introduites profitent en
                  général d'une forte hausse. Cependant, le cas général n'est
                  pas une règle et, dans des périodes de fort volume, Qwantic ne
                  peut pas assurer un passage d'ordre au moment le plus propice.
                  Le nombre de paire introduite sur Binance ne peut pas être
                  connu à l'avance.
                </p>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DefinitionListing;
