import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { firebase_app, auth0 } from "./data/config";
import {
  configureFakeBackend,
  authHeader,
  handleResponse,
} from "./services/fack.backend";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// Import custom components for Qwantic
import PageAPI from "./components/qwantic/API/pageAPI";
import QwanticTradingBot from "./components/qwantic/qwantic-trading-bot/qwantictradingbot";
import PageSubscription from "./components/qwantic/subscriptions/pageSubscriptions";
import Dashboard from "./components/qwantic/dashboard/dashboard";
import Faq from "./components/qwantic/FAQ/faq";

// Parrainage and Affiliation
import PageProgramme from "./components/qwantic/programme/programmePage";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import store from "./store";
import App from "./components/app";
import { Auth0Provider } from "@auth0/auth0-react";

// pages
import Signin from "./auth/signin";

import Signup from "./pages/signup";

import ResetPwd from "./pages/resetPwd";
import Maintenance from "./pages/maintenance";
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

//config data
import configDB from "./data/customizer/config";
import Callback from "./auth/callback";
import PageDCA from "./components/qwantic/DCA/pageDCA";
import PageSmartWallet from "./components/qwantic/smartWallet/pageSmartWallet";
import ListingPage from "./components/qwantic/newCoinListing/ListingPage";
import BuyTheDipBotPage from "./components/qwantic/buyTheDipBot/BuyTheDipBotPage";

// setup fake backend
configureFakeBackend();

const Root = () => {
  const abortController = new AbortController();
  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {
    const requestOptions = { method: "GET", headers: authHeader() };
    fetch("/users", requestOptions).then(handleResponse);
    const color = localStorage.getItem("color");
    console.log(color);
    const layout =
      localStorage.getItem("layout_version") ||
      configDB.data.color.layout_version;
    firebase_app.auth().onAuthStateChanged(setCurrentUser);
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;

    document
      .getElementById("color")
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/css/${color}.css`
      );

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <Auth0Provider
        domain={auth0.domain}
        clientId={auth0.clientId}
        redirectUri={auth0.redirectUri}
      >
        <Provider store={store}>
          <BrowserRouter basename={`/`}>
            <Switch>
              {/* A enlever */}
              {/* <Route
                path={`${process.env.PUBLIC_URL}/`}
                render={() => <Maintenance />}
              /> */}
              {/* <Route
                path={`${process.env.PUBLIC_URL}/`}
                component={Maintenance}
              /> */}

              <Route
                path={`${process.env.PUBLIC_URL}/affiliate/:affiliateID`}
                component={Signup}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={Signin}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/pages/signup`}
                component={Signup}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/pages/resetPwd`}
                component={ResetPwd}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/pages/maintenance`}
                component={Maintenance}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error400`}
                component={Error400}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error401`}
                component={Error401}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error403`}
                component={Error403}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error404`}
                component={Error404}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error500`}
                component={Error500}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error503`}
                component={Error503}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/callback`}
                render={() => <Callback />}
              />

              {currentUser !== null ? (
                <App>
                  {/* A enlever  */}

                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/`}
                    component={Maintenance}
                  /> */}

                  {/* dashboard menu */}
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    render={() => {
                      return (
                        <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
                      );
                    }}
                  />

                  {/* API */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/apimanagement`}
                    component={PageAPI}
                  />

                  {/* Smart Bot */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/bot/dca`}
                    component={PageDCA}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/bot/newlisting`}
                    component={ListingPage}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/bot/buyTheDipBot`}
                    component={BuyTheDipBotPage}
                  />

                  {/* Qwantic Trading Bot */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/bot/qwantic-trading-bot`}
                    component={QwanticTradingBot}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    component={Dashboard}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/faq`}
                    component={Faq}
                  />

                  {/* Affiliation et parrainage */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/programme`}
                    component={PageProgramme}
                  />

                  {/* Abonnement */}

                  <Route
                    path={`${process.env.PUBLIC_URL}/wallet`}
                    component={PageSmartWallet}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/subscription`}
                    component={PageSubscription}
                  />
                </App>
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Switch>
          </BrowserRouter>
        </Provider>
      </Auth0Provider>
    </div>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
