import React, { Fragment } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import ChoiceButton from "../qwantic-trading-bot/buttonselect";
import SweetAlert from "sweetalert2";
import Joi from "joi-browser";
import { postBotBTD } from "../../../firebase-services/firebaseBTD";
import { Alert } from "reactstrap";
import { firebase_app } from "../../../data/config";
import { UncontrolledTooltip } from "reactstrap";

class FormBTDBot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "My BTD Bot",
      exchange: "",
      allocation: 10,
      leverage: 1,
      mode: "automatique",
      error: false,
      capital: 0,
      updateFirebase: true,
      frequency: "semaine",
      occurence: 1,
      occurmin: 1,
      occurmax: 6,
      limitTime: 1,
      limitmin: 1,
      limitmax: 25,
      crypto: this.props.crypto,
      achatImmediat: false,
      sellStrat: "dynamic",
      partialSell: false,
      compoundsInterest: false,
      APIS: [],
    };
  }

  frequencyList = {
    jour: { occurence: [1, 2], limitTime: 45 },
    semaine: { occurence: [1, 2, 3, 4, 5, 6], limitTime: 25 },
    mois: { occurence: [1, 2, 3, 4], limitTime: 12 },
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getAPIs(user);
      }
    });
  };

  getAPIs = (user) => {
    const getAllAPI = firebase_app.functions().httpsCallable("getAllAPI");
    getAllAPI({ UID: user.uid }).then((result) => {
      let APIS = result.data.map((api) => {
        const tmp = {
          path: api.id,
          exchange: api.exchange,
        };
        return tmp;
      });

      APIS = APIS.filter((api) =>
        ["ftx", "binanceSpot", "bybit"].includes(api.path)
      );
      if (APIS == []) {
        this.setState({ APIS, exchange: "", errorAPI: true });
      } else {
        this.props.onSelectExchange(APIS[0].exchange);
        this.setState({ APIS, exchange: APIS[0].exchange, errorAPI: false });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.exchange !== this.state.exchange) {
      this.filterSymbolAccordingExchange();
    }

    if (prevProps.crypto.length !== this.props.crypto.length) {
      // test
      this.filterSymbolAccordingExchange();
    }
  }

  schema = {
    allocation: Joi.number()
      .integer()
      .min(10)
      .max(1000)
      .required()
      .label("Allocation"),
    frequency: Joi.string().required().label("Frequence"),
    occurence: Joi.number().required().label("Occurence"),
    limitTime: Joi.number().required().label("Limite Transaction"),
  };

  // Filter Symbol according Exchange
  filterSymbolAccordingExchange = () => {
    var cryptoTmp = [...this.props.crypto];
    cryptoTmp = cryptoTmp.filter((c) => {
      var tmp = c.exchange;
      var filterName = "";

      if (this.state.exchange == "Binance Spot") {
        filterName = "binanceSpot";
      }
      if (this.state.exchange == "FTX") {
        filterName = "ftx";
      }
      if (this.state.exchange == "ByBit") {
        filterName = "bybit";
      }

      return tmp.includes(filterName);
    });

    this.setState({ crypto: cryptoTmp });
  };
  //

  handleSubmitBTDBot = (event) => {
    event.preventDefault();

    const {
      allocation,
      frequency,
      occurence,
      limitTime,
      crypto,
      achatImmediat,
      compoundsInterest,
      partialSell,
      sellStrat,
      exchange,
      errorAPI,
    } = this.state;
    // const { crypto } = this.props;
    const result = Joi.validate(
      { allocation, frequency, occurence, limitTime },
      this.schema,
      { abortEarly: false }
    );

    if (
      errorAPI ||
      result.error ||
      crypto.length === 0 ||
      crypto.length > this.props.remainingBTDBot
    ) {
      if (crypto.length > this.props.remainingBTDBot) {
        SweetAlert.fire({
          title: "Erreur !",
          text: `Votre plan actuel ne permet pas de soumettre autant de bot BTD - ${this.props.remainingBTDBot} restant(s)`,
          icon: "error",
        });
      } else {
        SweetAlert.fire({
          title: "Erreur !",
          text: "Veuillez vérifier vos données",
          icon: "error",
        });
      }
    } else {
      SweetAlert.fire({
        title: "Good job!",
        text: "Vos données sont en cours d'envoie",
        icon: "info",
      });

      // Formater le nom de l'exchange
      let exchangeTmp = "";
      if (exchange === "Binance Spot") {
        exchangeTmp = "binanceSpot";
      }
      if (exchange === "FTX") {
        exchangeTmp = "ftx";
      }
      if (exchange == "ByBit") {
        exchangeTmp = "bybit";
      }

      let bots = {};
      let listAchatImmediat = [];
      // Idée décaler le bot de 6h, pour exécuter le premier trade dans la journée

      var starting = 0;

      if (achatImmediat) {
        // Si achat immédiat est actif on décale dans le passé d'une heure
        const uid = firebase_app.auth().currentUser.uid;
        starting = new Date().getTime() / 1000 - 2 * 3600;
        // on établie la liste des achats immediats à faire

        const postAchatImmediat = firebase_app
          .functions()
          .httpsCallable("postAchatImmediat");

        crypto.forEach((element) => {
          listAchatImmediat.push({
            symbol: element.symbol,
            nbrExecuted: 0,
            amount: allocation,

            typeBot: "BTD",
            side: "BUY",
          });
        });
        console.log(listAchatImmediat);
        postAchatImmediat({ listAchatImmediat, uid, exchange: exchangeTmp });
      }

      crypto.forEach((element) => {
        bots[element.symbol] = {
          allocation,
          frequency,
          occurence,
          limitTime,
          allowSell: false,
          executed: 0,
          token: 0,
          amount: 0,
          startSec: starting, // Incrementé à chaque fois
          compoundsInterest,
          partialSell,
          sellStrat,
          exchange: exchangeTmp,
          bank: 0,
        };
      });

      postBotBTD(bots);
    }
  };
  handleChangeExchange = (event) => {
    this.props.onSelectExchange(event.target.value);
    this.setState({ exchange: event.target.value });
  };
  handleChangeSellStrat = (event) => {
    this.setState({ sellStrat: event.target.id });
  };
  handleChangeOptionSell = (event) => {
    this.setState({ partialSell: event.target.checked });
  };
  handleChangeCompoundsInterest = (event) => {
    this.setState({ compoundsInterest: event.target.checked });
  };
  handleChangeAllocation = (event) => {
    this.setState({ allocation: parseInt(event.target.value) });
  };
  handleChangeAchatImmediat = (event) => {
    this.setState({ achatImmediat: event.target.checked });
  };
  handleChangeOccurence = (value) => {
    this.setState({
      occurence: value,
      limitTime: 1,
      limitmax: this.frequencyList[this.state.frequency].limitTime * value,
    });
  };

  handleChangeLimit = (value) => {
    this.setState({
      limitTime: value,
    });
  };

  handleChangeFrequency = (event) => {
    this.setState({
      frequency: event.target.value,
      occurence: 1,
      occurmin: 1,
      limitTime: 1,
      occurmax: this.frequencyList[event.target.value].occurence.slice(-1)[0],
      limitmax: this.frequencyList[event.target.value].limitTime,
    });
  };

  render() {
    const {
      allocation,
      frequency,
      occurence,
      occurmin,
      occurmax,
      limitmax,
      limitmin,
      limitTime,
      crypto,
    } = this.state;

    return (
      <Fragment>
        <form
          className="theme-form mega-form"
          onSubmit={this.handleSubmitBTDBot}
        >
          <div className="card">
            <div className="card-header">
              <h5>
                <i className="fa fa-cog fa-1x"></i>
                {"    Configuration Buy The Dip Bot"}
              </h5>
            </div>

            <div className="card-body">
              {/* Choix de l'exchange  */}
              <div className="form-group">
                <h6>Exchange</h6>
                <select
                  className="form-control digits"
                  id="exampleFormControlSelect9"
                  defaultValue="Binance Futures USDT-M"
                  onChange={this.handleChangeExchange}
                >
                  {this.state.APIS.map((api) => {
                    return <option key={api.path}> {api.exchange}</option>;
                  })}
                </select>
              </div>

              <div className="form-group">
                <h6>
                  Cryptomonnaies
                  {this.state.crypto.length !== 0
                    ? ` (${this.state.crypto.length})`
                    : ""}
                </h6>
                {this.state.crypto.length === 0 ? (
                  <span className="f-s-italic">
                    {"Aucune crypto sélectionnée"}
                  </span>
                ) : (
                  ""
                )}

                <div className="row">
                  <div className="col-12">
                    {crypto.map((c) => (
                      <span
                        key={c.symbol}
                        className="badge badge-pill badge-light"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.props.onDeleteSymbolBotForm(c.symbol)
                        }
                      >
                        <img
                          src={`/assets/icons/${c.symbol.toLowerCase()}.svg`}
                          alt={"Icon"}
                          className={"m-r-5"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/icons/error.svg";
                          }}
                          width={15}
                          height={15}
                        />
                        {c.symbol}
                        <span className="f-s-italic">/USDT </span>
                        <i className="fa fa-times"></i>
                      </span>
                    ))}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h6>Allocation par cryptomonnaies (USDT)</h6>
                {this.state.allocation < 10 && this.state.allocation !== "" ? (
                  <span className="text-danger">Minimum de 10 USDT</span>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">USDT</span>
                      </div>
                      <input
                        className="form-control"
                        type="namebot"
                        name="number"
                        value={allocation}
                        onChange={this.handleChangeAllocation}
                        onClick={() => {
                          this.setState({ allocation: "" });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <h6>
                      {" "}
                      {`Fréquence : maximum ${occurence} / ${frequency}`}
                    </h6>
                    <span className="f-s-italic">
                      {
                        "Détermine la durée minimum entre deux achats consécutifs de la même cryptomonnaie."
                      }
                    </span>
                    <div className="m-10"></div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <ChoiceButton
                    value={frequency}
                    doSomethingAfterClick={this.handleChangeFrequency}
                    buttons={[
                      { name: "jour", label: "Jour" },
                      { name: "semaine", label: "Semaine" },
                      { name: "mois", label: "Mois" },
                    ]}
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    <Slider
                      min={occurmin}
                      max={occurmax}
                      step={1}
                      labels={{
                        1: `x${occurmin}`,
                        100: `x${occurmax}`,
                      }}
                      format={(e) => e}
                      value={occurence}
                      orientation="horizontal"
                      onChange={this.handleChangeOccurence}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <h6>{`Nombre total de transaction `}</h6>
                    <span className="f-s-italic">
                      {
                        "Détermine le nombre total de transaction effectué par le bot pour chaque paire."
                      }
                    </span>
                    <div className="m-10"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Slider
                      min={limitmin}
                      max={limitmax}
                      step={1}
                      labels={{ 1: limitmin, 1000: limitmax }}
                      format={(e) => e}
                      value={limitTime}
                      orientation="horizontal"
                      onChange={this.handleChangeLimit}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <h6>Options</h6>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <label className="d-block">
                      <input
                        className="checkbox_animated"
                        id="achatImmediat"
                        type="checkbox"
                        onClick={this.handleChangeAchatImmediat}
                      />
                      <strong>Achat immédiat</strong>
                    </label>
                    {this.state.achatImmediat ? (
                      <div>
                        <Alert color="warning">
                          <p>
                            {
                              "En cliquant sur Soumettre, l'achat sera immédiat sur toutes les cryptos sélectionnées !"
                            }
                          </p>
                        </Alert>
                        <div className="m-5"></div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* Options de vente */}
                <div className="row">
                  <div className="col-12">
                    <label className="d-block">
                      <input
                        className="checkbox_animated"
                        id="optionSell"
                        type="checkbox"
                        onClick={this.handleChangeOptionSell}
                      />
                      <strong>Activer vente partielle</strong>
                    </label>
                    {this.state.partialSell ? (
                      <div>
                        <span className="f-s-italic">
                          {
                            "La vente partielle permet de prendre des profits sur les achats précédents et les recomposer lors du prochain achat"
                          }
                        </span>{" "}
                        {/* Profil de vente partielle  */}
                        <div className="card-body animate-chk">
                          <div className="row">
                            <div className="col">
                              <label
                                className="d-block"
                                htmlFor="sellStrat1"
                                id="ToolTipSellStrat1"
                              >
                                <input
                                  className="radio_animated"
                                  id="performance"
                                  type="radio"
                                  name="sellStrat"
                                  onClick={this.handleChangeSellStrat}
                                />
                                {"Profit USDT maximum"}
                              </label>
                              <UncontrolledTooltip
                                placement="top"
                                target="ToolTipSellStrat1"
                              >
                                {
                                  "Vente de 50% des tokens tout en gardant au minimum 35% des tokens investis"
                                }
                              </UncontrolledTooltip>

                              <label
                                className="d-block"
                                htmlFor="sellStrat2"
                                id="ToolTipSellStrat2"
                              >
                                <input
                                  className="radio_animated"
                                  id="dynamic"
                                  type="radio"
                                  name="sellStrat"
                                  onClick={this.handleChangeSellStrat}
                                  defaultChecked
                                />
                                {"Equilibré USDT/Crypto"}
                              </label>
                              <UncontrolledTooltip
                                placement="top"
                                target="ToolTipSellStrat2"
                              >
                                {
                                  "Vente de 30% des tokens tout en gardant au minimum 40% des tokens investis"
                                }
                              </UncontrolledTooltip>
                              <label
                                className="d-block"
                                htmlFor="sellStrat3"
                                id="ToolTipSellStrat3"
                              >
                                <input
                                  className="radio_animated"
                                  id="stacking"
                                  type="radio"
                                  name="sellStrat"
                                  onClick={this.handleChangeSellStrat}
                                />
                                {"Stacking de token"}
                              </label>
                              <UncontrolledTooltip
                                placement="top"
                                target="ToolTipSellStrat3"
                              >
                                {
                                  "Vente de 20% des tokens tout en gardant au minimum 50% des tokens investis"
                                }
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        </div>
                        {/* Option intéret recomposées */}
                        <label className="d-block m-l-20">
                          <input
                            className="checkbox_animated"
                            id="compoundsInterest"
                            type="checkbox"
                            onClick={this.handleChangeCompoundsInterest}
                          />
                          <strong>Recomposer les profits</strong>
                        </label>
                        <div className=" m-l-20">
                          <span className="f-s-italic">
                            {`En activant l'option, le bot ajoutera à l'allocation initiale de (${allocation} USDT) une partie des profits et capitaux dégagés de la vente précédente. Par exemple, sur un achat de ${allocation}$ fermé à ${
                              allocation * 1.2
                            }$, la nouvelle position sera augmenté de ${
                              allocation * 1.2
                            }$ soit un total de ${allocation * 2.2}$`}
                          </span>{" "}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <span className="col">
                  <strong>Fréquence max</strong>
                </span>
                <div>
                  {`${occurence} x `}

                  <strong>{` par ${frequency}`}</strong>
                </div>
              </div>
              <div className="row ">
                <span className="col">
                  <strong>Montant total de l'investissement</strong>
                </span>
                <div>
                  {parseInt(allocation * this.props.crypto.length * limitTime)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  <strong>USDT</strong>
                </div>
              </div>

              <div className="row ">
                <span className="col">
                  <strong>Durée minimal de l'investissement </strong>
                </span>
                <div>
                  {limitTime / occurence < 1
                    ? "<1 "
                    : parseInt(limitTime / occurence) + " "}

                  <strong>{`  ${frequency}${
                    limitTime / occurence > 1 && frequency !== "mois" ? "s" : ""
                  }`}</strong>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                  onClick={this.props.onSubmitBot}
                >
                  {"Soumettre"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default FormBTDBot;
