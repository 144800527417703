import React, { Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { BookOpen, FileText, MessageCircle } from "react-feather";
import one from "../../../assets/images/faq/1.jpg";
import two from "../../../assets/images/faq/2.jpg";
import three from "../../../assets/images/faq/3.jpg";
import four from "../../../assets/images/faq/4.jpg";
import FaqRender from "./faq_function";

const FaqComponent = () => {
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="FAQ" />
      <div className="container">
        <div className="faq-wrap">
          <div className="row">
            <div className="col-xl-4 xl-100">
              <div className="card bg-primary">
                <div className="card-body">
                  <div className="media faq-widgets">
                    <div className="media-body">
                      <h5>{"Blog"}</h5>
                      <p>
                        {
                          "Consultez nos articles sur le blog Qwantic à l'adresse:"
                        }
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-secondary"
                          href="https://blog.qwantic.io/"
                        >
                          {" "}
                          Lien vers le blog{" "}
                        </a>{" "}
                        {
                          ". Vous trouverez des articles informatifs sur les crypto-monnaies en général, mais aussi des tutoriels pour mieux comprendre la solution et les outils."
                        }
                      </p>
                    </div>
                    <FileText />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 xl-50 col-sm-6">
              <div className="card bg-primary">
                <div className="card-body">
                  <div className="media faq-widgets">
                    <div className="media-body">
                      <h5>{"Questions"}</h5>
                      <p>
                        {
                          "Les questions les plus fréquemments posées ont été rassemblées et classées par catégorie. N'hésitez pas à regarder ci-dessous pour trouver les réponses à vos questions"
                        }
                      </p>
                    </div>
                    <BookOpen />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 xl-50 col-sm-6">
              <div className="card bg-primary">
                <div className="card-body">
                  <div className="media faq-widgets">
                    <div className="media-body">
                      <h5>{"Support"}</h5>
                      <p>
                        {
                          "Pour toutes questions et/ou problèmes techniques, vous pouvez nous contacter sur notre Telegram d'assistance et de support. Le lien est le suivant: "
                        }
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-secondary"
                          href="https://t.me/qwanticsupport"
                        >
                          {" "}
                          Lien vers le telegram{" "}
                        </a>
                      </p>
                    </div>
                    <MessageCircle />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="header-faq">
                <h5 className="mb-0">{"Questions fréquentes"}</h5>
              </div>
              <div
                className="row default-according style-1 faq-accordion"
                id="accordionoc"
              >
                <div className="col-xl-8 xl-60 col-lg-6 col-md-7">
                  <FaqRender theme={"start"} />

                  <FaqRender theme={"security"} />

                  <FaqRender theme={"work"} />

                  <FaqRender theme={"subscription"} />
                </div>
              </div>
            </div>

            {/* Lien utiles vers le blog  */}
            <div className="col-lg-12">
              <div className="header-faq">
                <h5 className="mb-0">{"Article de Blog"}</h5>
              </div>
              <div className="row">
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={one} alt="" />
                      <div className="product-hover">
                        <ul>
                          <i
                            className="icon-link"
                            href="https://blog.qwantic.io/gestion-de-la-perte-qwantic-trading-bot/"
                            target="_blank"
                          ></i>
                        </ul>
                      </div>
                    </div>

                    <div className="card-body">
                      <h6>{"Gestion de la perte"}</h6>
                      <p>
                        {
                          "Dans cet article, découvrez comment les algorithmes gèrent la perte et les solutions mises en place pour protégez vos portefeuilles."
                        }
                      </p>
                    </div>
                    <div className="card-footer">
                      <span className="pull-right">
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={two} alt="" />
                      <div className="product-hover">
                        <ul>
                          <li>
                            <i
                              className="icon-link"
                              href="https://blog.qwantic.io/question-frequentes/"
                              target="_blank"
                            ></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body">
                      <h6>{"Autres questions fréquentes"}</h6>
                      <p>
                        {
                          "Cet article reprend plus en détail les questions fréquentes de l'utilisateur concernant l'utilisation de la plateforme Qwantic."
                        }
                      </p>
                    </div>
                    <div className="card-footer">
                      <span className="pull-right">
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star-o font-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={three} alt="" />
                      <div className="product-hover">
                        <ul>
                          <li>
                            <i
                              className="icon-link"
                              href="https://blog.qwantic.io/slippage-prix-repere-et-decalage-quid/"
                              target="_blank"
                            ></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body">
                      <h6>{"Slippage, prix - Quid"}</h6>
                      <p>
                        {
                          "Le monde réel n’est pas immédiat, tout arrive avec une latence et ce qu’on pense instantannée ne l’est pas, d’autant plus quand les forces engagées sont grandes."
                        }
                      </p>
                    </div>
                    <div className="card-footer">
                      <span className="pull-right">
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={four} alt="" />
                      <div className="product-hover">
                        <ul>
                          <li>
                            <i
                              className="icon-link"
                              href="https://blog.qwantic.io/un-bot-de-trading-a-800-en-2-minutes-mythe-et-realite/"
                              target="_blank"
                            ></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body">
                      <h6>{"Construction d'un algorithme"}</h6>
                      <p>
                        {
                          "Session pratique sur l’élaboration d’un algorithme de trading. Notre thématique, déconstruire et reconstruire une stratégie pour apprendre à être critique sur les solutions disponibles sur le Web."
                        }
                      </p>
                    </div>
                    <div className="card-footer">
                      <span className="pull-right">
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star font-primary"></i>
                        <i className="fa fa-star-half-o font-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FaqComponent;
