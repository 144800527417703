import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { firebase_app } from "../../../data/config";
import DataTable from "react-data-table-component";
import { getAllSymbol } from "./../../../firebase-services/firebaseDCA";
import { Doughnut } from "react-chartjs-2";

import { Col, Card, CardHeader, CardFooter } from "reactstrap";

class PageSmartWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balanceFutures: [],
      balanceSpot: [],
      balance: [],
      capitalTot: "N/A",
      capitalBTC: "N/A",
      capitalStable: "N/A",
      perBTC: 0,
      risk: 0,
      perStable: 0,
      loading: true,
      doughnutData: null,
    };
  }

  getMetrics = (balance) => {
    var capitalTot = 0;
    var capitalBTC = 0;
    var capitalStable = 0;
    var capitalLargeCap = 0;
    var capitalMidCap = 0;

    balance.map((crypto) => {
      // Calcul Capital Total
      capitalTot += crypto.value;
      if (crypto.symbol === "BTC") {
        capitalBTC += crypto.value;
      }
      if (["USDT", "BUSD", "USDC", "UST", "TUSD"].includes(crypto.symbol)) {
        capitalStable += crypto.value;
      }
      if (["ETH", "BNB", "ADA", "SOL", "XRP"].includes(crypto.symbol)) {
        capitalLargeCap += crypto.value;
      }

      if (
        ["LUNA", "DOT", "DOGE", "AVAX", "SHIB", "MATIC"].includes(crypto.symbol)
      ) {
        capitalMidCap += crypto.value;
      }
    });

    // Calcul Risk
    const perBTC = (100 * capitalBTC) / capitalTot;
    const perStable = (100 * capitalStable) / capitalTot;
    const perLargeCap = (100 * capitalLargeCap) / capitalTot;
    const perMidCap = (100 * capitalMidCap) / capitalTot;
    const perSmallCap =
      (100 *
        (capitalTot -
          capitalBTC -
          capitalStable -
          capitalLargeCap -
          capitalMidCap)) /
      capitalTot;

    var risk = 0;
    if (perBTC < 10) {
      risk += 20;
    }
    if (perBTC < 20 && perBTC >= 10) {
      risk += 10;
    }
    if (perStable < 15) {
      risk += 20;
    }
    if (perLargeCap > 20) {
      risk += 15;
    }
    if (perMidCap > 15) {
      risk += 15;
    }
    if (perSmallCap > 20) {
      risk += 30;
    }

    this.setState({
      capitalTot: parseInt(capitalTot * 100) / 100,
      capitalBTC: parseInt(capitalBTC * 100) / 100,
      capitalStable: parseInt(capitalStable * 100) / 100,
      perBTC: parseInt(perBTC * 100) / 100,
      perStable: parseInt(perStable * 100) / 100,
      risk,
      loading: false,
    });
    return true;
  };

  format_output = (output) => {
    var n = Math.log(output) / Math.LN10;
    var x = 4 - n;
    if (x < 0) x = 0;
    output = output.toFixed(x);
    return output;
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        let balancetmp = [];
        let balance = [];
        let value = 0;
        const getAllWalletLoggedUser = firebase_app
          .functions()
          .httpsCallable("getAllWalletLoggedUser");

        getAllWalletLoggedUser({ UID: user.uid })
          .then((res) => {
            balancetmp = res.data[0]
              .concat(res.data[1])
              .concat(res.data[2])
              .concat(res.data[3])
              .concat(res.data[4]);
            // Regroupement
            for (var i = 0; i < balancetmp.length - 1; i++) {
              for (var j = i + 1; j < balancetmp.length; j++) {
                if (balancetmp[i].symbol === balancetmp[j].symbol) {
                  balancetmp[j].amount =
                    balancetmp[i].amount + balancetmp[j].amount;
                  balancetmp[i].amount = 0;
                }
              }
            }
            // Filter
            balancetmp = balancetmp.filter((crypto) => crypto.amount !== 0);

            getAllSymbol().then((cryptos) => {
              balancetmp.map((capital) => {
                cryptos.every((crypto) => {
                  if (capital.symbol === crypto.symbol) {
                    value =
                      capital.symbol === "USDT"
                        ? parseFloat(capital.amount)
                        : parseFloat(capital.amount) * parseFloat(crypto.price);
                    balance.push({
                      name: crypto.name,
                      symbol: capital.symbol,
                      amount: this.format_output(capital.amount),
                      value,
                    });

                    return false;
                  }
                  if (capital.symbol === "USDT") {
                    value = parseFloat(capital.amount);

                    balance.push({
                      name: "Tether",
                      symbol: "USDT",
                      amount: this.format_output(capital.amount),
                      value,
                    });

                    return false;
                  }

                  return true;
                });
              });

              // Concatenate Similar Crypto :

              // this.setState({
              //   balance,
              // });
              // Calculate Risk
              this.getMetrics(balance);
              // Create Dougnut Data
              let labels = [];
              let dataDoughnut = [];
              balance.map((line) => {
                labels.push(`${line.name} (${line.symbol})`);
                dataDoughnut.push(parseInt(100 * line.value) / 100);
              });

              const doughnutData = {
                labels: labels,
                datasets: [
                  {
                    data: dataDoughnut,
                    backgroundColor: [
                      "#8dd3c7",
                      "#ffffb3",
                      "#bebada",
                      "#fb8072",
                      "#80b1d3",
                      "#fdb462",
                      "#b3de69",
                      "#fccde5",
                      "#d9d9d9",
                      "#bc80bd",
                      "#ccebc5",
                      "#ffed6f",
                      "#8dd3c7",
                      "#ffffb3",
                      "#bebada",
                      "#fb8072",
                      "#80b1d3",
                      "#fdb462",
                      "#b3de69",
                      "#fccde5",
                      "#d9d9d9",
                      "#bc80bd",
                      "#ccebc5",
                      "#ffed6f",
                    ],
                  },
                ],
              };
              const doughnutOption = {
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
                plugins: {
                  datalabels: {
                    display: false,
                    color: "white",
                  },
                },
              };

              this.setState({
                balance,
                doughnutOption,
                doughnutData,
              });
            });
          })
          .catch((res) => console.log(res));
      }
    });
  };

  columns = [
    {
      name: "",
      selector: "name",
      sortable: false,
      center: true,
      width: "50px",
      cell: (row) => {
        {
          return (
            <img
              src={`/assets/icons/${row.symbol.toLowerCase()}.svg`}
              alt={"Icon"}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/icons/error.svg";
              }}
              width={25}
              height={25}
            />
          );
        }
      },
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Symbole",
      selector: "symbol",
      sortable: true,
      center: true,
    },

    {
      name: "Token",
      selector: "amount",
      sortable: true,
      center: true,
    },

    {
      name: "Value (USDT)",
      selector: "value",
      sortable: true,
      center: true,
      cell: (row) => {
        return <div>{parseInt(100 * row.value) / 100 + "$"}</div>;
      },
    },
  ];

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb title="Smart Wallet" parent="Dashboard" />
          <div className="card">
            <div className="card-header">
              <div className="row justify-content-center">
                <div className="loader-box">
                  <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Breadcrumb title="Smart Wallet" parent="Dashboard" />
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-xl-4">
                <div className="card">
                  <div className="card-header">
                    <h5>{"Mes actifs"}</h5>
                  </div>

                  <div className="card-body chart-block">
                    <div className="carddoughnutData, doughnutOption,-body">
                      <Doughnut
                        data={this.state.doughnutData}
                        options={this.state.doughnutOption}
                        width={778}
                        height={400}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Encart  */}

              <Col xl="2" sm="6">
                <Card>
                  <CardHeader>
                    <h5 className="">{"Valeur USDT"}</h5>
                  </CardHeader>
                  <div>
                    <div className="project-widgets text-center">
                      <h3 className="font-primary counter">
                        {this.state.capitalTot}
                      </h3>
                      <h6 className="mb-0">USDT</h6>
                    </div>
                  </div>
                  <CardFooter className="project-footer">
                    <h6 className="mb-0">{"Montant total du portefeuille"}</h6>
                  </CardFooter>
                </Card>
              </Col>
              <Col xl="2" sm="6">
                <Card>
                  <CardHeader>
                    <h5 className="">{"Capital BTC"}</h5>
                  </CardHeader>
                  <div>
                    <div className="project-widgets text-center">
                      <h3 className="font-primary counter">
                        {this.state.capitalBTC}
                      </h3>
                      <h6 className="mb-0">USDT</h6>
                    </div>
                  </div>
                  <CardFooter className="project-footer">
                    <h6 className="mb-0">{`${this.state.perBTC}% du portefeuille`}</h6>
                  </CardFooter>
                </Card>
              </Col>

              <Col xl="2" sm="6">
                <Card>
                  <CardHeader>
                    <h5 className="">{"Stable Coin"}</h5>
                  </CardHeader>
                  <div>
                    <div className="project-widgets text-center">
                      <h3 className="font-primary counter">
                        {this.state.capitalStable}
                      </h3>
                      <h6 className="mb-0">USDT</h6>
                    </div>
                  </div>
                  <CardFooter className="project-footer">
                    <h6 className="mb-0">{`${this.state.perStable}% du portefeuille`}</h6>
                  </CardFooter>
                </Card>
              </Col>
              <Col xl="2" sm="6">
                <Card>
                  <CardHeader>
                    <h5 className="">{"Risque"}</h5>
                  </CardHeader>
                  <div>
                    <div className="project-widgets text-center">
                      <h3 className="font-primary counter">
                        {`${this.state.risk} %`}
                      </h3>
                      <h6 className="mb-0">
                        {this.state.risk <= 30
                          ? "Faible"
                          : this.state.risk >= 60
                          ? "Fort"
                          : "Moyen"}
                      </h6>
                    </div>
                  </div>
                  <CardFooter className="project-footer">
                    <h6 className="mb-0">
                      Basé sur la répartition du portefeuille
                    </h6>
                  </CardFooter>
                </Card>
              </Col>
            </div>

            <div className="card">
              <div className="card-header">
                <h5>{"Récapitulatif"}</h5>
              </div>

              <div className="card-body ">
                <div className="datatable-react">
                  <DataTable
                    columns={this.columns}
                    data={this.state.balance}
                    pagination
                    dense={true}
                    subHeader={false}
                    noHeader={true}
                    defaultSortField={"value"}
                    defaultSortAsc={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default PageSmartWallet;
