import { runInContext } from "lodash";
import React, { Component } from "react";
import DataTable from "react-data-table-component";

class TableCryptoDCA extends Component {
  constructor(props) {
    super(props);
    this.state = { selectCriteria: [] };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.symbolsSelected.length !== this.props.symbolsSelected.length
    ) {
      var symbolSelected = [];
      this.props.symbolsSelected.map((el) => symbolSelected.push(el.symbol));
      this.setState({
        selectCriteria: symbolSelected,
      });
    }
  }

  columns = [
    {
      name: "",
      selector: "name",
      sortable: false,
      center: true,
      width: "30px",
      cell: (row) => {
        {
          return (
            <img
              src={`/assets/icons/${row.symbol.toLowerCase()}.svg`}
              alt={"Icon"}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/icons/error.svg";
              }}
              width={25}
              height={25}
            />
          );
        }
      },
    },
    {
      name: "Nom",
      selector: "name",
      sortable: true,
      center: true,
      cell: (row) => {
        {
          return <div>{` ${row.name} (${row.symbol})`}</div>;
        }
      },
    },
    // {
    //   name: "Symbole",
    //   selector: "symbol",
    //   sortable: true,
    //   center: true,
    // },
    {
      name: "Valeur (USDT)",
      selector: "price",
      sortable: true,
      center: true,
    },
    {
      name: "Exchange",
      selector: "exchange",
      sortable: true,
      center: true,
      cell: (row) => {
        {
          return (
            <div>
              {row.exchange.map((e, index) => {
                if (e === "binanceSpot") {
                  return (index > 0 ? " - " : "") + "Binance";
                }
                if (e === "ftx") {
                  return (index > 0 ? " - " : "") + "FTX";
                }
                if (e === "bybit") {
                  return (index > 0 ? " - " : "") + "ByBit";
                }
              })}
            </div>
          );
        }
      },
    },
    {
      name: "Variation 24H (%)",
      selector: "variation",
      sortable: true,
      center: true,
      cell: (row) => {
        return (
          <div className={row.variation >= 0 ? "font-success" : "font-danger"}>
            <i
              className={
                row.variation >= 0
                  ? "fa fa-long-arrow-up"
                  : "fa fa-long-arrow-down"
              }
            ></i>
            {` ${row.variation}%`}
          </div>
        );
      },
    },

    {
      name: "Volume 24H (M$)",
      selector: "volume",
      sortable: true,
      center: true,

      cell: (row) => {
        return <div>{parseInt(100 * row.volume) / 100 + "M$"}</div>;
      },
    },
  ];

  render() {
    const { selectCriteria } = this.state;
    return (
      <div className="datatable-react">
        <DataTable
          key={selectCriteria.length}
          columns={this.columns}
          data={this.props.symbols}
          pagination
          selectableRows
          onSelectedRowsChange={this.props.onSelect}
          // subHeaderComponent={this.subHeaderComponentMemo}
          selectableRowSelected={(row) => selectCriteria.includes(row.symbol)}
          dense={true}
          subHeader={false}
          noHeader={true}
          // striped={true}
          // center={true}

          // persistTableHead
          // contextActions={contextActions}
          // onSelectedRowsChange={handleRowSelected}
        />
      </div>
    );
  }
}

export default TableCryptoDCA;
