import React, { Fragment } from "react";
const Maintenance = () => {
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper maintenance-bg">
          <div className="container">
            <ul className="maintenance-icons">
              <li>
                <i className="fa fa-cog"></i>
              </li>
              <li>
                <i className="fa fa-cog"></i>
              </li>
              <li>
                <i className="fa fa-cog"></i>
              </li>
            </ul>
            <div className="maintenance-heading">
              <h2 className="headline">{"Maintenance"}</h2>
            </div>
            <h4 className="sub-content">
              {
                "Le site est actuellement en maintenance - Migration vers la version 3 de Qwantic"
              }{" "}
              <br />
            </h4>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Maintenance;
