import React, { Component, Fragment } from "react";
import { Col, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import {
  getNotifications,
  deleteNotification,
} from "../../../firebase-services/firebaseNotification";
import { firebase_app } from "../../../data/config";

class NotificationComponent extends Component {
  state = {
    notifications: [],
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getNotifications().then((notif) => {
          console.log(notif);

          this.setState({ notifications: notif });
        });
      }
    });
  };

  render() {
    const colNotif = [
      {
        name: "Date",
        selector: "date",
        width: "20%",
        sortable: true,
        center: true,
      },
      {
        name: "Message",
        width: "65%",
        selector: "msg",
        sortable: true,
        center: true,
        cell: (row) => {
          return (
            <div className={row.type === "ERROR" ? "font-danger" : ""}>
              {`${row.type} - ${row.msg}`}
            </div>
          );
        },
      },

      {
        name: "",
        selector: "state",
        width: "15%",
        sortable: false,
        center: true,
        cell: (row) => {
          return (
            <button
              className="btn btn-danger btn-xs m-l-20"
              onClick={() => {
                deleteNotification(row.key);
                var tmpNotif = [...this.state.notifications];
                tmpNotif = tmpNotif.filter((notif) => notif.key !== row.key);

                this.setState({
                  notifications: tmpNotif,
                });
              }}
            >
              <strong>X</strong>
            </button>
          );
        },
      },
    ];

    return (
      <Fragment>
        <div className="card">
          <div className="card-header">
            <h5>{`Notifications (${this.state.notifications.length})`}</h5>
          </div>

          <div className="card-header">
            {this.state.notifications.length > 0 ? (
              //   <div className="table-responsive support-table">
              <DataTable
                columns={colNotif}
                data={this.state.notifications}
                // striped={true}
                center={true}
                dense={true}
                // pagination
                noHeader={true}
              />
            ) : (
              //   </div>
              <span> Aucune notification</span>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default NotificationComponent;
