import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import logo from "../assets/images/qwantic-logo-dark.png";
import { firebase_app } from "../data/config";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const Signup = () => {
  let { affiliateID } = useParams();

  const [currentUser, setCurrentUser] = useState(null);

  const addAffiliate = (id, uid) => {
    const submitAffiliation = firebase_app
      .functions()
      .httpsCallable("submitAffiliation");
    // Add affiliate ID
    console.log(id);
    submitAffiliation({
      uid: uid,
      affiliateID: id,
    })
      .then((res) => {})
      .catch((e) => console.log(e));
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.elements);
    const { email, password } = e.target.elements;
    try {
      firebase_app
        .auth()
        .createUserWithEmailAndPassword(email.value, password.value)
        .then((userCredential) => {
          userCredential.user.sendEmailVerification();
          if (affiliateID) {
            const uid = userCredential.user.uid;
            addAffiliate(affiliateID, uid);
          }
          setCurrentUser(true);
        })
        .catch((err) => {
          setTimeout(() => {
            toast.error(err.message);
          }, 200);
        });
    } catch (error) {
      console.log("Erreur");

      alert(error);
    }
  };

  if (currentUser) {
    return <Redirect to="/dashboard" />;
  }

  const SignInWithGoogle = () => {
    var google_provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(google_provider)
      .then((userCredential) => {
        console.log(userCredential);
        if (affiliateID) {
          const uid = userCredential.user.uid;
          addAffiliate(affiliateID, uid);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- sign up page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4 p-4">
                      <h4 className="text-center">{"Inscription"}</h4>
                      <h6 className="text-center">
                        {"Entrer votre e-mail et mot de passe pour s'inscrire"}
                      </h6>

                      <form className="theme-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label for="email" className="col-form-label">
                            {"E-mail"}
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="email@email.com"
                          />
                        </div>
                        <div className="form-group">
                          <label for="password" className="col-form-label">
                            {"Mot de passe"}
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            placeholder="**********"
                          />
                        </div>

                        <div className="form-row">
                          <div className="col-sm-4">
                            <button className="btn btn-primary" type="submit">
                              {"S'enregistrer"}
                            </button>
                          </div>
                          <div className="col-sm-8">
                            <div className="text-left mt-2 m-l-20">
                              {"Déjà membre ?"}  
                              <a
                                className="btn-link text-capitalize"
                                href="login.html"
                              >
                                {"Se connecter"}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="form-divider"></div>
                        <div className="social mt-3">
                          <div className="form-group btn-showcase d-flex">
                            <button
                              className="btn social-btn btn-google d-inline-block"
                              onClick={SignInWithGoogle}
                            >
                              <i className="fa fa-google"> Google</i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- sign up page ends--> */}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Signup;
