import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import CurvePerformance from "./curvePerformance";
import { firebase_app } from "../../../data/config";
import TableBot from "../qwantic-trading-bot/tableBot";
import TableAPI from "../API/tableAPI";

import { Alert, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import NotificationComponent from "../notification/notificationComponent";

class Dashboard extends Component {
  state = { loading: true, active: false, verified: false, pilltabs: 1 };

  // Charge in Redux ?

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          this.setState({ verified: true });
        }

        // Check if user is verified

        var getCurrentSub = firebase_app
          .functions()
          .httpsCallable("getCurrentSub");

        getCurrentSub({
          UID: user.uid,
        })
          .then((result) => {
            // test result
            const {
              active,
              name,
              quantity,
              dateSubscription,
              subscriptionID,
              cancel_at_period_end,
              canceled_date_sec,
            } = result.data;

            this.setState({
              active,
              name,
              quantity,
              subscriptionID,
              dateSubscription,
              cancel_at_period_end,
              loading: false,
              canceled_date_sec,
            });
          })
          .catch((e) => console.log(e));
      }
    });
  };

  sendVerification = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        user.sendEmailVerification();
      }
    });
  };
  alertVerified = () => {
    const { loading, verified } = this.state;

    if (!loading && !verified) {
      return (
        <Alert color="danger">
          <p>
            Veuillez vérifier votre adresse e-mail{" "}
            <a href="#" onClick={this.sendVerification()}>
              en cliquant ici
            </a>
          </p>
        </Alert>
      );
    }
  };
  returnInfoSubscription = () => {
    const {
      loading,
      active,
      dateSubscription,
      name,
      cancel_at_period_end,
      canceled_date_sec,
    } = this.state;
    if (!loading) {
      if (active) {
        if (cancel_at_period_end) {
          var date = new Date(1000 * canceled_date_sec);
          return (
            <Alert color="success outline">
              <p>
                Votre abonnement - <strong>{name}</strong> - a été annulé et
                restera actif jusqu'au{" "}
                {date.toLocaleString("fr-FR", { timeZone: "UTC" })}
              </p>
            </Alert>
          );
        } else {
          var date = new Date(1000 * dateSubscription);
          return (
            <Alert color="success outline">
              <p>
                Vous avez un abonnement en cours - <strong>{name}</strong> -
                depuis le {date.toLocaleString("fr-FR", { timeZone: "UTC" })}
              </p>
              <button
                className="btn btn-success btn-xs"
                onClick={this.handleModalCancel}
              >
                Annuler mon abonnement ?
              </button>
            </Alert>
          );
        }
      }
      return (
        <Alert color="danger">
          <p>Aucun abonnement en cours - Découvrez nos offres !</p>
        </Alert>
      );
    } else {
      return (
        <Alert color="success outline">
          <p>Chargement en cours ...</p>
        </Alert>
      );
    }
  };

  handleChangeTab = (value) => {
    this.setState({ pilltabs: value });
  };

  render() {
    return (
      <Fragment>
        {this.returnInfoSubscription()}
        {this.alertVerified()}
        <Breadcrumb title="Dashboard" parent="Dashboard" />
        <div className="container">
          <NotificationComponent />
          <div className="row">
            <Col xl="3" sm="6">
              <Card>
                <CardHeader>
                  <h5 className="">{"Abonnement"}</h5>
                </CardHeader>
                <CardBody>
                  <div className="project-widgets text-center">
                    <h3 className="font-primary counter">
                      {this.state.loading
                        ? "Chargement"
                        : this.state.active
                        ? "Actif"
                        : "Inactif"}
                    </h3>
                    <h6 className="mb-0">
                      {this.state.active
                        ? this.state.name
                        : "Découvrez nos offres"}
                    </h6>
                    <h6 className="mb-0">
                      {this.state.cancel_at_period_end ? "(Annulé)" : ""}
                    </h6>
                  </div>
                </CardBody>
                <CardFooter className="project-footer"></CardFooter>
              </Card>
            </Col>
            <div className="col-sm-12 col-xl-9">
              <TableBot />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-xl-12">
              {this.state.active ? <CurvePerformance /> : ""}
            </div>
          </div>

          <TableAPI />
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;

{
  /* <Nav className="nav-dark">
            <NavItem>
              <NavLink
                href="#javascript"
                className={this.state.pilltabs == 1 ? "active" : ""}
                onClick={() => this.handleChangeTab(1)}
              >
                <i className="icofont icofont-ui-home"></i>
                {"Qwantic Trading Bot"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#javascript"
                className={this.state.pilltabs == 2 ? "active" : ""}
                onClick={() => this.handleChangeTab(2)}
              >
                <i className="icofont icofont-man-in-glasses"></i>
                {"DCA"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#javascript"
                className={this.state.pilltabs == 3 ? "active" : ""}
                onClick={() => this.handleChangeTab(3)}
              >
                <i className="icofont icofont-contacts"></i>
                {"Smart Bot"}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.pilltabs}>
            <TabPane className="fade show" tabId="1">
              <p className="mb-0 m-t-30">
                {
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
                }
              </p>
            </TabPane>
            <TabPane tabId="2">
              <p className="mb-0 m-t-30">
                {
                  "Lorem Ipsum is simply dummy  text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
                }
              </p>
            </TabPane>
            <TabPane tabId="3">
              <p className="mb-0 m-t-30">
                {
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
                }
              </p>
            </TabPane>
          </TabContent> */
}
