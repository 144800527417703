import React, { Fragment } from "react";
import { deleteAPI } from "../../../firebase-services/firebaseAPI";
import { firebase_app } from "../../../data/config";
import Swal from "sweetalert2";

class PermissionAPI extends React.Component {
  state = {
    permissions: [
      {
        exchange: "Binance Futures",
        tradingBot: true,
        DCA: false,
        BTD: false,
        wallet: true,
      },
      {
        exchange: "Binance Spot",
        tradingBot: false,
        DCA: true,
        BTD: true,
        wallet: true,
      },
      {
        exchange: "ByBit",
        tradingBot: false,
        DCA: true,
        BTD: true,
        wallet: true,
      },
      {
        exchange: "FTX",
        tradingBot: false,
        DCA: true,
        BTD: true,
        wallet: true,
      },
    ],
  };

  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="card-header">
            <h5>{"Permissions API"}</h5>
          </div>

          <div className="table-responsive col-12 m-10">
            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">{"#"}</th> */}
                  <th scope="col">
                    <div className="row justify-content-center ">
                      {"Exchange"}
                    </div>
                  </th>
                  <th scope="col">
                    <div className="row justify-content-center ">
                      {"Qwantic Trading Bot"}
                    </div>
                  </th>
                  <th scope="col">
                    <div className="row justify-content-center ">{"DCA"}</div>
                  </th>
                  <th scope="col">
                    <div className="row justify-content-center ">
                      {"Buy The Dip"}
                    </div>
                  </th>
                  <th scope="col">
                    <div className="row justify-content-center ">
                      {"Smart Wallet"}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.permissions.map((api) => {
                  return (
                    <tr key={api.exchange}>
                      {/* <th scope="row">{"1"}</th> */}
                      <th>{api.exchange}</th>
                      <td>
                        <div className="row justify-content-center ">
                          <i
                            className={
                              api.tradingBot
                                ? "font-success fa fa-check-circle"
                                : "font-danger fa fa-times-circle"
                            }
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="row justify-content-center ">
                          <i
                            className={
                              api.DCA
                                ? "font-success fa fa-check-circle"
                                : "font-danger fa fa-times-circle"
                            }
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="row justify-content-center ">
                          <i
                            className={
                              api.BTD
                                ? "font-success fa fa-check-circle"
                                : "font-danger fa fa-times-circle"
                            }
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="row justify-content-center ">
                          <i
                            className={
                              api.wallet
                                ? "font-success fa fa-check-circle"
                                : "font-danger fa fa-times-circle"
                            }
                          ></i>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PermissionAPI;
