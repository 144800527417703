import React, { useState, Fragment } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const DefinitionDCA = () => {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h5>DCA ?</h5>
        </CardHeader>
        <CardBody>
          <div className="tabbed-card">
            <Nav className="pull-right  nav-pills nav-primary">
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                >
                  {"Définition"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                >
                  {"Avantages"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => setActiveTab("3")}
                >
                  {"Limites"}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p className="mb-0">
                  Le <strong>DCA</strong> ou « Dollar Cost Averaging » est une
                  stratégie d'investissement qui consiste à acheter une
                  cryptomonnaie pour la même somme en dollars (ou en €) à des
                  intervalles réguliers et programmés à l'avance.
                </p>
              </TabPane>
              <TabPane tabId="2">
                <p className="mb-0">
                  La méthode DCA permet de <strong>diminuer son risque </strong>
                  en venant profiter des variations du marché pour
                  éventuellement diminuer son prix d'achat. Cette méthode est
                  très avantageuse par rapport à un investissement unique sur
                  une cryptomonnaie.
                </p>
              </TabPane>
              <TabPane tabId="3">
                <p className="mb-0">
                  Pour être gagnant, le DCA suppose un marché haussier sur le
                  long terme,{" "}
                  <strong>il s'agit d'une stratégie de placement</strong>, ainsi
                  la prise de gains/pertes est gérée par l'utilisateur.
                </p>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DefinitionDCA;
