import React, { Fragment } from "react";
import LogoQwantic from "./qwanticWhite.svg";

const ImageQwantic = () => {
  return (
    <Fragment>
      <div style={{ width: "150 px", marginLeft: "20%", opacity: "0.3" }}>
        <img src={LogoQwantic} alt="Qwantic Logo" className="" />
      </div>
    </Fragment>
  );
};

export default ImageQwantic;
