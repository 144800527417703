import React, { Fragment } from "react";
import { deleteAPI } from "../../../firebase-services/firebaseAPI";
import { firebase_app } from "../../../data/config";
import Swal from "sweetalert2";
class TableAPI extends React.Component {
  state = {
    APIS: [],
    update: this.props.value,
    delete: 0,
    waitForDB: true,
    missingAPIKey: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.value !== this.props.value ||
      prevState.delete !== this.state.delete
    ) {
      firebase_app.auth().onAuthStateChanged((user) => {
        if (user) {
          this.getAPIs(user);
        }
      });
    }
  }

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getAPIs(user);
      }
    });
  };

  getAPIs = (user) => {
    const getAllAPI = firebase_app.functions().httpsCallable("getAllAPI");
    getAllAPI({ UID: user.uid }).then((result) => {
      let APIS = result.data.map((api) => {
        const tmp = {
          path: api.id,
          exchange: api.exchange,
          key: api.myAPIKey,
          status: "on",
        };
        return tmp;
      });

      var missingAPIKey = false;
      if (this.props.mandatoryExchange) {
        missingAPIKey = true;
      }

      APIS.forEach((element) => {
        if (element.exchange === this.props.mandatoryExchange) {
          missingAPIKey = false;
        }
      });

      this.setState({ APIS, waitForDB: false, missingAPIKey });
    });
  };

  handleDelete = (path) => {
    Swal.fire({
      title: "Supprimer la clé API ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.value) {
        Swal.fire({ title: "Supprimé!", icon: "success" });
        deleteAPI(path);
        this.setState({ delete: this.state.delete + 1 });
      }
    });
  };
  render() {
    if (this.state.waitForDB) {
      return (
        <Fragment>
          <div className="card">
            <div className="card-header">
              <h5>{"Mes clés API"}</h5>
              <div className="row justify-content-center">
                <div className="loader-box">
                  <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="card">
            <div className="card-header">
              <h5>{"Mes clés API"}</h5>
              {this.state.missingAPIKey ? (
                <span className="text-danger">
                  {`Attention, ce bot nécessite une clé API ${this.props.mandatoryExchange} - `}
                  <a href="/apimanagement">{"Ajouter clé"}</a>
                </span>
              ) : (
                ""
              )}
              {this.state.APIS.length === 0 ? (
                <span>
                  {"Aucune API n'est enregistrée - "}
                  <a href="/apimanagement">{"Ajoutez une clé API"}</a>
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="table-responsive col-12 m-5 ">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th scope="col">{"#"}</th> */}

                    {/* <th scope="col">
                      {" "}
                      <div className="row justify-content-center ">{""}</div>
                    </th> */}

                    <th scope="col">
                      {" "}
                      <div>{"Exchange"}</div>
                    </th>
                    <th scope="col">
                      {" "}
                      <div className="row justify-content-center ">{"API"}</div>
                    </th>
                    <th scope="col">
                      {" "}
                      <div className="row justify-content-center ">
                        {"Status"}
                      </div>
                    </th>
                    <th scope="col">
                      {" "}
                      <div className="row justify-content-center ">
                        {"Action"}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.APIS.map((api) => {
                    return (
                      <tr key={api.path}>
                        {/* <th scope="row">{"1"}</th> */}

                        {/* <td>
                          <img
                            src={`/assets/icons/${api.path.toLowerCase()}.svg`}
                            alt={"Icon"}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/assets/icons/error.svg";
                            }}
                            height={25}
                          />
                        </td> */}

                        <th>
                          <div>{`${api.exchange}`}</div>
                        </th>
                        <td>
                          {" "}
                          <div className="row justify-content-center ">
                            {api.key.substring(0, 5)}****
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="row justify-content-center ">
                            <i className="fa fa-check-circle"></i>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="row justify-content-center ">
                            <button
                              onClick={() => this.handleDelete(api.path)}
                              className="btn btn-danger"
                            >
                              <strong>X</strong>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default TableAPI;
