import { firebase_app } from "../data/config";

export const getAffiliationInfo = async (uid) => {
  const res = await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("programme")
    .doc("ambassadeur")
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      const affiliateID = data.affiliateID;
      const isAmbassadeur = data.isAmbassadeur;

      return { affiliateID, isAmbassadeur };
    });

  return res;
};

export const getParrainageInfo = async (uid) => {
  const res = await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("programme")
    .doc("parrainage")
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      const IDparrainage = data.IDparrainage;
      const avantageParrain = data.avantageParrain;

      return { IDparrainage, avantageParrain };
    });

  return res;
};
