let listFaq = [
  //Start
  {
    theme: "Démarrer avec Qwantic Bot Trading",
    title:
      "J'ai des positions ouvertes sur Binance, que se passe-t-il si je commence Qwantic?",
    answer:
      "Le robot Qwantic trade sur le Litecoin, BNB, Ripple et Bitcoin. Si vous possédez des positions sur l'une de ses cryptos dans le portefeuille Futures, elles ne seront pas fermés. Néanmoins, nous conseillons d'éviter la cohabitation de vos trades personnelles ou d'autres robots. Vous pouvez avoir des positions ouvertes dans d'autres portefeuilles (Margin, Spot, Earn,etc.).",
  },
  {
    theme: "Démarrer avec Qwantic Bot Trading",
    title: "Comment est calculé la somme réellement investie ?",
    answer:
      "Vous ne définissez pas une somme précise sur Qwantic, mais un pourcentage de votre portefeuille Futures. Ainsi, en définissant une allocation de 30% et si vous possédez 1000 USDT sur votre compte, Qwantic va allouer maximum 300 USDT. Si votre capital total monte à 1100 USDT, Qwantic va allouer maximum 330 USDT.",
  },
  {
    theme: "Démarrer avec Qwantic Bot Trading",
    title: "Combien de temps avant le démarrage du bot Qwantic ?",
    answer:
      "Si les paramètres renseignés sont correctes, Qwantic analyse les modifications plusieurs fois par jour. Ainsi, il faudra attendre quelques heures avant le démarrage (ou l'arrêt) du bot sur votre compte Binance.",
  },
  {
    theme: "Démarrer avec Qwantic Bot Trading",
    title: "Dois-je modifier le levier sur Binance ?",
    answer:
      "Le levier est automatiquement mis à jour par Qwantic lors de l'enregistrement ou la modification des données (onglet Paramètres Qwantic). Il est égal au risque. Ainsi il n'est pas nécessaire de modifier le levier sur la plateforme. Cependant, ce dernier ne doit pas être modifié en cours d'utilisation du robot.",
  },

  //Security
  {
    theme: "Sécurité de mes informations",
    title: "Comment Qwantic traite mes données ?",
    answer:
      "En tant qu'entreprise, nous nous devons de respecter votre vie privée et l'ensemble des conditions sont disponibles dans notre Politique de confidentialité.",
  },
  {
    theme: "Sécurité de mes informations",
    title: "Quel accès je donne à Qwantic ?",
    answer:
      "Qwantic a accès à un nombre limité d'information et vous avez le droit à l'oubli. Vous partagez avec nous vos informations personnelles (Nom, Prénom, Email), vos données de paiement ne sont pas enregistrées par Qwantic et sont transmises de manière sécurisée à notre partenaire PayPal (norme PCI DSS et cryptage des données ). Vous partagez également vos clés API Binance, ce qui authorise Qwantic à passer des ordres avec votre signature. En aucun cas Qwantic a un accès à votre capital et ne pourra faire aucune opération en dehors du cadre imposé par Binance.",
  },
  {
    theme: "Sécurité de mes informations",
    title: "Est-ce dangereux de donner mes clés API Binance ?",
    answer:
      "Les clés API sont des accès privilégiés à votre compte, ce pourquoi elles sont considérées comme des données sensibles et doivent être utilisées avec précaution. Néanmoins, Binance vous permet de garder le contrôle total des accès. Le partage des clés est nécessaire pour le fonctionnement du bot, cela permet de passer des ordres avec votre accord. En aucun cas, Qwantic peut retirer les fonds ou sortir ces derniers de la plateforme Binance. Vos identifiants API sont transférés et stockés de manière sécurisée, et conservés uniquement pendant la période d'utilisation de nos services.",
  },

  //Work
  {
    theme: "Fonctionnement de Qwantic Bot Trading",
    title: "Comment fonctionne le robot ?",
    answer:
      "Le bot analyse le marché toutes les 5 minutes pour trouver des opportunités de Long et Short sur différentes cryptomonnaies. Une fois la position ouverte, il analyse chaque demi-seconde l'évolution du cours et décide si la position doit être fermée ou non. La durée moyenne d'une position est d'environ 50 minutes.",
  },
  {
    theme: "Fonctionnement de Qwantic Bot Trading",
    title: "J'arrête mon robot, j'ai une trade ouverte ?",
    answer:
      "Si le robot est désactivé, le robot va clôturer la position selon la stratégie en cours. L'arrêt total du robot peut prendre quelques heures.",
  },
  {
    theme: "Fonctionnement de Qwantic Bot Trading",
    title: "Je dois changer mes données API ou allocation, que faire ?",
    answer:
      "Reprennez la démarche décrite dans l'onglet 'Commencer' avec les nouvelles données, Qwantic prendra en compte les modifications dans les heures suivantes.",
  },

  //Subscription
  {
    theme: "Abonnement et résiliation",
    title: "Je souhaite résilier, que faire ?",
    answer:
      "Dans l'onglet Page d'acceuil ou Mon abonnement, cliquez sur Annuler mon abonnement pour le résilier. La résiliation prendra effet à la fin de la période souscrite (par tranche d'un mois), le robot sera toujours actif pendant cette période. N'oubliez pas de désactiver le robot dans Paramètres Qwantic",
  },
  {
    theme: "Abonnement et résiliation",
    title: "Je souhaite mettre à jour mon abonnement ?",
    answer:
      "Vous trouverez en page d'acceuil un bouton Annuler/Modifier. L'action de modifier met en pause l'abonnement actuel le temps qu'un nouvel abonnement soit pris. Si l'utilisateur met à jour vers un abonnement supérieur, un remboursement de l'abonnement précédent sera fait par nos équipes. Paramètres Qwantic",
  },
  {
    theme: "Abonnement et résiliation",
    title: "Est-ce que désactiver le bot annule mon abonnement ?",
    answer:
      "Non, le bot Qwantic est mis en pause, mais l'abonnement est toujours actif.",
  },
];

export function getFaq(theme) {
  let listFaqfinal = [];

  switch (theme) {
    default:
      break;
    case "start":
      listFaqfinal = listFaq.filter(
        (t) => t.theme === "Démarrer avec Qwantic Bot Trading"
      );
      break;
    case "security":
      listFaqfinal = listFaq.filter(
        (t) => t.theme === "Sécurité de mes informations"
      );
      break;
    case "work":
      listFaqfinal = listFaq.filter(
        (t) => t.theme === "Fonctionnement de Qwantic Bot Trading"
      );
      break;
    case "subscription":
      listFaqfinal = listFaq.filter(
        (t) => t.theme === "Abonnement et résiliation"
      );
      break;
  }

  for (var i = 0; i < listFaqfinal.length; i++) {
    listFaqfinal[i]["collapse"] = i;
    listFaqfinal[i]["stateCollapse"] = false;
  }

  return listFaqfinal;
}

export function getFaqTitle(theme) {
  let Faqtitle = "";

  switch (theme) {
    default:
      break;
    case "start":
      Faqtitle = "Démarrer avec Qwantic Bot Trading";
      break;
    case "security":
      Faqtitle = "Sécurité de mes informations";
      break;
    case "work":
      Faqtitle = "Fonctionnement de Qwantic Bot Trading";
      break;
    case "subscription":
      Faqtitle = "Abonnement et résiliation";
      break;
  }

  return Faqtitle;
}
