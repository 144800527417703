import React, { Component, Fragment } from "react";
import SubscriptionCard from "./subscriptionCard";
import Breadcrumb from "../../common/breadcrumb";
import { firebase_app } from "../../../data/config";
import { abonnement } from "./infoAbonnement";
import { Alert } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PopUpCancel from "./popUpCancel";

class PageSubscription extends Component {
  state = {
    products: abonnement,
    active: false,
    name: "",
    price: 0,
    dateSubscription: 0,
    loading: true,
    payment: false,
    showModal: false,
    subscriptionID: "",
    cancel_at_period_end: false,
  };

  returnInfoSubscription = () => {
    const {
      loading,
      active,
      dateSubscription,
      name,
      cancel_at_period_end,
      canceled_date_sec,
    } = this.state;
    if (!loading) {
      if (active) {
        var date = 0;
        if (cancel_at_period_end) {
          date = new Date(1000 * canceled_date_sec);
          return (
            <Alert color="success outline">
              <p>
                Votre abonnement - <strong>{name}</strong> - a été annulé et
                restera actif jusqu'au{" "}
                {date.toLocaleString("fr-FR", { timeZone: "UTC" })}
              </p>
            </Alert>
          );
        } else {
          date = new Date(1000 * dateSubscription);
          return (
            <Alert color="success outline">
              <p>
                Vous avez un abonnement en cours - <strong>{name}</strong> -
                depuis le {date.toLocaleString("fr-FR", { timeZone: "UTC" })}
              </p>
              <button
                className="btn btn-success btn-xs"
                onClick={this.handleModalCancel}
              >
                Annuler mon abonnement ?
              </button>
            </Alert>
          );
        }
      }
      return (
        <Alert color="danger">
          <p>Aucun abonnement en cours - Découvrez nos offres !</p>
        </Alert>
      );
    } else {
      return (
        <Alert color="success outline">
          <p>Chargement en cours ...</p>
        </Alert>
      );
    }
  };

  handleModalCancel = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleUpdate = () => {
    this.componentDidMount();
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        var getCurrentSub = firebase_app
          .functions()
          .httpsCallable("getCurrentSub");

        getCurrentSub({
          UID: user.uid,
        })
          .then((result) => {
            // test result
            const {
              active,
              name,
              quantity,
              dateSubscription,
              subscriptionID,
              cancel_at_period_end,
              canceled_date_sec,
            } = result.data;

            this.setState({
              active,
              name,
              quantity,
              subscriptionID,
              dateSubscription,
              cancel_at_period_end,
              canceled_date_sec,
              loading: false,
              uid: user.uid,
            });
          })
          .catch((e) => console.log(e));
      }
    });
  };

  render() {
    const {
      showModal,
      name,
      subscriptionID,
      dateSubscription,
      cancel_at_period_end,
      active,
      quantity,
    } = this.state;
    var activeAndNotCancel = active && !cancel_at_period_end;

    return (
      <Fragment>
        <Breadcrumb title="Abonnement" parent="Dashboard" />
        {this.returnInfoSubscription()}
        <div className="container">
          <Modal isOpen={showModal} toggle={this.handleModalCancel}>
            <ModalHeader toggle={this.handleModalCancel}>
              Annulation
            </ModalHeader>
            <ModalBody>
              <PopUpCancel
                value={{
                  name,
                  subscriptionID,
                  dateSubscription,
                }}
                onCancel={this.handleUpdate}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleModalCancel}>
                {"Fermer"}
              </Button>
            </ModalFooter>
          </Modal>

          <div className="row justify-content-center">
            {this.state.products.map((product) => {
              return (
                <div className="col-sm-12 col-xl-3" key={product.name}>
                  <SubscriptionCard
                    user={this.state.uid}
                    product={product}
                    updatePage={() => this.handleUpdate()}
                    subscribed={{
                      activeAndNotCancel,
                      name,
                      quantity,
                      subscriptionID,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PageSubscription;
