import React, { Fragment } from "react";
import TableAPI from "../API/tableAPI";
import Breadcrumb from "../../common/breadcrumb";
import TableDCA from "./TableDCA";
import FormDCABot from "./FormDCABot";
import TableCryptoDCA from "./tableCrypto";
import SmartFilter from "./smartFilter";
import { firebase_app } from "../../../data/config";
import {
  getAllSymbol,
  getAllBotsDCA,
} from "./../../../firebase-services/firebaseDCA";
import { Alert } from "reactstrap";
import _ from "lodash";
import DefinitionDCA from "./cardDefinition";
import LogDCA from "./logTrade";
import { abonnement } from "../subscriptions/infoAbonnement";

class PageDCA extends React.Component {
  state = {
    update: 0,
    symbols: [],
    symbolsSort: [],
    selectedCrypto: [],
    botsDCA: {},
    limitNbrBotDCA: 5,
    currentDCABot: 0,
    capitalUSDT: -1,
    resCapitalAvailable: false,
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getAllSymbol().then((result) =>
          this.setState({ symbols: result, symbolsSort: result })
        );

        getAllBotsDCA().then((result) => {
          this.setState({ botsDCA: result, currentDCABot: _.size(result) });
        });

        var getCurrentSub = firebase_app
          .functions()
          .httpsCallable("getCurrentSub");

        getCurrentSub({ UID: user.uid })
          .then((result) => {
            // test result
            const { active, name } = result.data;
            if (active) {
              const abo = abonnement.filter((abo) => abo.name === name);

              this.setState({
                limitNbrBotDCA: abo[0]["DCA"][0],
              });
            }
          })
          .catch((e) => console.log(e));
      }
    });
  };

  handleSelectExchange = (exchange) => {
    this.setState({
      capitalUSDT: -1,
      resCapitalAvailable: false,
      exchange,
    });
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        let exchangeTmp = "";
        if (exchange === "Binance Spot") {
          exchangeTmp = "getCapitalUSDTSpot";
        }
        if (exchange === "FTX") {
          exchangeTmp = "getCapitalUSDTSpotFTX";
        }
        if (exchange == "ByBit") {
          exchangeTmp = "getCapitalUSDTSpotByBit";
        }
        const getCapitalUSDTSpot = firebase_app
          .functions()
          .httpsCallable(exchangeTmp);

        getCapitalUSDTSpot({ UID: user.uid }).then((res) => {
          this.setState({
            capitalUSDT: res.data.balanceSpot,
            resCapitalAvailable: true,
            exchange,
          });
        });
      }
    });
  };

  handleSubmitBot = () => {
    this.componentDidMount();
    setTimeout(() => {
      const update = this.state.update + 1;
      this.setState({ update, selectedCrypto: [] });
    }, 2000);
  };

  handleDeleteSymbolBotForm = (symbol) => {
    const tmp = this.state.selectedCrypto.filter((c) => c.symbol !== symbol);
    this.setState({
      selectedCrypto: tmp,
      currentDCABot: this.state.currentDCABot - 1,
    });
  };

  handleChangeFilter = (data) => {
    var s = this.state.symbols.filter((e) => {
      return (
        _.includes(e.symbol, data.toUpperCase()) ||
        _.includes(e.name.toUpperCase(), data.toUpperCase())
      );
    });

    this.setState({ symbolsSort: s });
  };

  handleChangeRadioFilter = (filterValue) => {
    var s = [];
    if (filterValue === 0) {
      this.setState({ symbolsSort: this.state.symbols });
    }

    if (filterValue === 1) {
      s = this.state.symbols.sort(function (first, second) {
        return second.volume - first.volume;
      });
      s = s.filter((element, index) => index < 10);
      this.setState({ symbolsSort: s });
    }

    if (filterValue === 2) {
      s = this.state.symbols.sort(function (first, second) {
        return second.variation - first.variation;
      });
      s = s.filter((element, index) => index < 10);
      this.setState({ symbolsSort: s });
    }
  };
  handleSelect = ({ selectedRows }) => {
    this.setState({ selectedCrypto: selectedRows });
  };
  render() {
    return (
      <Fragment>
        <Breadcrumb title="DCA" parent="Smart Bot" />
        <div className="container">
          {this.state.limitNbrBotDCA <= this.state.currentDCABot ? (
            <Alert color="danger">
              <p>
                Vous avez déjà atteint le maximum de{" "}
                <strong>{this.state.limitNbrBotDCA}</strong> bots DCA avec votre
                formule. Augmentez votre limite !
              </p>
            </Alert>
          ) : (
            <Alert color="info">
              <p>
                Vous pouvez encore configurer{" "}
                <strong>
                  {this.state.limitNbrBotDCA - this.state.currentDCABot}
                </strong>{" "}
                DCA avec votre formule.
              </p>
            </Alert>
          )}

          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-5">
                <DefinitionDCA />
              </div>

              <div className="col-sm-7">
                <TableAPI value={null} mandatoryExchange={"Binance Spot"} />
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>{"Cryptomonnaies disponibles"}</h5>
                  </div>
                  <div className="card-body datatable-react">
                    <SmartFilter
                      onChangeFilter={this.handleChangeFilter}
                      onChangeRadioFilter={this.handleChangeRadioFilter}
                    />
                    <TableCryptoDCA
                      symbolsSelected={this.state.selectedCrypto}
                      symbols={this.state.symbolsSort}
                      onSelect={this.handleSelect}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-xl-12">
                {this.state.capitalUSDT >= 0 ? (
                  <Alert color="info">
                    <p>
                      {"Vous avez actuellement "}
                      <strong>
                        {parseInt(this.state.capitalUSDT * 100) / 100}
                      </strong>{" "}
                      USDT sur votre compte Spot
                    </p>
                  </Alert>
                ) : (
                  <Alert color="danger">
                    <p>
                      {this.state.resCapitalAvailable
                        ? "Problème de lecture - Avez-vous configuré votre clé API Spot ?"
                        : "Lecture du capital en cours ..."}
                    </p>
                  </Alert>
                )}
              </div>

              <div className="col-sm-12 col-xl-5">
                <FormDCABot
                  crypto={this.state.selectedCrypto}
                  remainingDCABot={
                    this.state.limitNbrBotDCA - this.state.currentDCABot
                  }
                  onSelectExchange={this.handleSelectExchange}
                  onSubmitBot={this.handleSubmitBot}
                  onDeleteSymbolBotForm={this.handleDeleteSymbolBotForm}
                />
              </div>
              <div className="col-sm-12 col-xl-7">
                <TableDCA value={this.state.update} bots={this.state.botsDCA} />
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h5>{"Historique des transactions"}</h5>
              </div>
              <div className="card-body">
                <LogDCA data={this.state.symbols} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PageDCA;
