export const abonnement = [
  {
    name: "Qwantic Carbon",
    prices: [9.9],
    capitals: [500],
    quantities: [1],
    idPaypal: "P-9AN21981EY588751SMEYOOFY",
    DCA: [20],
    BTD: [10],
  },
  {
    name: "Qwantic Gold",
    prices: [24],
    capitals: [1500],
    quantities: [1],
    idPaypal: "P-72490630HG145620CME24ZFQ",
    DCA: [50],
    BTD: [10],
  },
  {
    name: "Qwantic Uranium",
    prices: [50, 74, 98, 144, 188],
    capitals: [5000, 7500, 10000, 15000, 20000],
    quantities: [1, 2, 3, 4, 5],
    idPaypal: "P-7TA6003454913381FMFMEB4Q",
    DCA: [200],
    BTD: [50],
  },
  {
    name: "Qwantic Platinium",
    prices: [380, 570, 950, 1425],
    capitals: [40000, 60000, 100000, 150000],
    quantities: [1, 2, 3, 4],
    idPaypal: "P-8X334186UD033871HMGLGC4I",
    DCA: [200],
    BTD: [100],
  },
];
