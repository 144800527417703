import React, { useState, useEffect } from "react";
import logo from "../assets/images/qwantic-logo-dark.png";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { firebase_app, googleProvider, Jwt_token } from "../data/config";

const Signin = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  useEffect(() => {
    if (value !== null) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  const loginAuth = async () => {
    try {
      await firebase_app.auth().signInWithEmailAndPassword(email, password);
      setValue(man);
      localStorage.setItem("token", Jwt_token);
      history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
    } catch (error) {
      setTimeout(() => {
        toast.error("E-mail ou mot de passe invalide");
      }, 200);
    }
  };

  const googleAuth = async () => {
    try {
      firebase_app
        .auth()
        .signInWithPopup(googleProvider)
        .then(function (result) {
          setValue(result.user.photoURL);
          localStorage.setItem("token", Jwt_token);
          setTimeout(() => {
            history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error("Email ou mot de passe invalide");
      }, 200);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{"Authentification"}</h4>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              {"Adresse E-mail"}
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email address"
                            />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Mot de passe"}
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <a href="/pages/resetPwd">Mot de passe oublié ?</a>
                          </div>
                          {/* <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">
                              {"Se souvenir de moi"}
                            </label>
                          </div> */}
                          <div className="form-group form-row mt-3 mb-0">
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => loginAuth()}
                            >
                              {"Se connecter"}
                            </button>
                          </div>

                          <div className="login-divider"></div>
                          <div className="social mt-3">
                            <div className="form-group btn-showcase d-flex">
                              <button
                                className="btn social-btn btn-google d-inline-block"
                                type="button"
                                onClick={googleAuth}
                              >
                                <i className="fa fa-google"> Google</i>
                              </button>
                            </div>
                          </div>
                        </form>
                        <h6 className="text-left mt-2 m-l-20">
                          {"Pas de compte ?"}  
                          <a className="btn-link" href="/pages/signup">
                            {"Créer mon compte Qwantic"}
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
