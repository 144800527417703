import React, { useState, Fragment } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const DefinitionBTD = () => {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h5></h5>
        </CardHeader>
        <CardBody>
          <div className="tabbed-card">
            <Nav className="pull-right  nav-pills nav-primary">
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                >
                  {"Définition"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                >
                  {"Avantages"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => setActiveTab("3")}
                >
                  {"Fonctionnement"}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p className="mb-0">
                  Le bot <strong>Buy The Dip</strong> est une stratégie proche
                  du DCA « Dollar Cost Averaging ». Une stratégie
                  d'investissement qui consiste à acheter une cryptomonnaie à
                  intervalles réguliers tout en{" "}
                  <strong>optimisant le point d'entrée.</strong>
                </p>
              </TabPane>
              <TabPane tabId="2">
                <p className="mb-0">
                  La méthode permet de <strong>diminuer son risque </strong> en
                  venant profiter des variations du marché pour éventuellement
                  diminuer son prix d'achat. <br />
                  Cette méthode est très avantageuse par rapport à un
                  investissement unique sur une cryptomonnaie.
                </p>
              </TabPane>
              <TabPane tabId="3">
                <p className="mb-0">
                  Contrairement au DCA qui fixe l'intervalle de temps entre deux
                  achats consécutif, le bot Buy The Dip permet d'acheter à
                  intervalle régulier tout en retardant le point d'achat si
                  nécessaire. <br />
                  Ainsi, le bot respecte un délai minimum entre deux achats et
                  place un achat uniquement si le marché présente une bonne
                  configuration. Un point d'entrée correspond à un creux du
                  marché (ou Dip), calculé à partir d'indicateurs techniques.
                </p>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DefinitionBTD;
