import React, { Component, Fragment } from "react";
import { Line } from "react-chartjs-2";
import { getAllPerformanceFutures } from "../../../firebase-services/firebasePerformance";
import { firebase_app } from "../../../data/config";
import { Col, Card, CardBody } from "reactstrap";

class CurvePerformance extends Component {
  state = {
    lineChartData: [],
    lineChartOptions: [],
    TWR24h: "N/A",
    TWR1w: "N/A",
    TWR1m: "N/A",
    TWRTot: "N/A",
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getDataCurve(user);
      }
    });
  };

  getDataCurve = () => {
    getAllPerformanceFutures().then((result) => {
      const {
        labels,
        dataPlot,
        fluxPlotDepot,
        fluxPlotRetrait,
        TWR24h,
        TWR1w,
        TWR1m,
        TWRPlot,
        TWRTot,
      } = result;

      const data = {
        labels: labels,

        datasets: [
          {
            label: "Capital Binance Futures",
            backgroundColor: "rgb(255, 99, 132,0)",
            borderColor: "rgb(255, 99, 132)",
            data: dataPlot,
            pointRadius: 2.5,
            yAxisID: "A",
          },
          {
            label: "Performance TWR (%)",
            backgroundColor: "rgb(72, 199, 142,0)",
            borderColor: "rgb(72, 199, 142)",
            data: TWRPlot,
            pointRadius: 0,
            yAxisID: "B",
          },
        ],
      };

      const config = {
        type: "line",
        options: {
          responsive: true, // other properties ....
          maintainAspectRatio: false,
        },

        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              id: "A",
              stacked: true,
              position: "left",
              ticks: {
                min: Math.min.apply(this, dataPlot) - 5,
                max: Math.max.apply(this, dataPlot) + 5,
                callback: function (value, index, ticks) {
                  return "$" + parseInt(value);
                },
              },
            },
            {
              id: "B",
              stacked: true,
              position: "right",
              gridLines: {
                display: false,
              },
              ticks: {
                min: Math.min.apply(this, TWRPlot) - 5,
                max: Math.max.apply(this, TWRPlot) + 5,
                callback: function (value, index, ticks) {
                  return parseInt(10 * value) / 10 + "%";
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };

      this.setState({
        lineChartData: data,
        lineChartOptions: config,
        TWR24h,
        TWR1w,
        TWR1m,
        TWRTot,
      });
    });
  };

  datasetKeyProvider = () => {
    return Math.random();
  };
  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="card-header">
            <h5>{"Performance Trading Bot"}</h5>
          </div>
          <div className="row p-l-20 p-r-20">
            <Col xl="3" sm="6">
              <Card>
                <CardBody>
                  <div className="project-widgets text-center">
                    <h3
                      className={
                        this.state.TWR24h.substring(0, 1) === "-"
                          ? "font-danger counter"
                          : "font-success counter"
                      }
                    >
                      {this.state.TWR24h}
                    </h3>
                    <h6 className="mb-0">Last 24h</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3" sm="6">
              <Card>
                <CardBody>
                  <div className="project-widgets text-center">
                    <h3
                      className={
                        this.state.TWR1w.substring(0, 1) === "-"
                          ? "font-danger counter"
                          : "font-success counter"
                      }
                    >
                      {this.state.TWR1w}
                    </h3>
                    <h6 className="mb-0">Last 7D</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3" sm="6">
              <Card>
                <CardBody>
                  <div className="project-widgets text-center">
                    <h3
                      className={
                        this.state.TWR1m.substring(0, 1) == "-"
                          ? "font-danger counter"
                          : "font-success counter"
                      }
                    >
                      {this.state.TWR1m}
                    </h3>
                    <h6 className="mb-0">Last 30D</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3" sm="6">
              <Card>
                <CardBody>
                  <div className="project-widgets text-center">
                    <h3
                      className={
                        this.state.TWRTot.substring(0, 1) === "-"
                          ? "font-danger counter"
                          : "font-success counter"
                      }
                    >
                      {this.state.TWRTot}
                    </h3>
                    <h6 className="mb-0">All Time</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>

          <div className="card-body chart-block">
            <Line
              data={this.state.lineChartData}
              options={this.state.lineChartOptions}
              datasetKeyProvider={this.datasetKeyProvider}
              width={778}
              height={window.innerWidth < 400 ? 1000 : 350}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CurvePerformance;
