import React, { Fragment } from "react";
import {
  getAffiliationInfo,
  getParrainageInfo,
} from "../../../firebase-services/firebaseProgramme";
import Breadcrumb from "../../common/breadcrumb";
import { firebase_app } from "../../../data/config";
import ParrainageComponent from "./parrainage";
import AmbassadeurComponent from "./ambassadeurComponent";

class PageProgramme extends React.Component {
  state = { isAmbassadeur: false, textButton: "Copier le lien" };
  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getAffiliationInfo(user.uid).then((res) => {
          this.setState({
            isAmbassadeur: res.isAmbassadeur,
            affiliateID: res.affiliateID,
          });

          getParrainageInfo(user.uid).then((res) => {
            console.log(res);
            this.setState({
              IDparrainage: res.IDparrainage,
              avantageParrain: res.avantageParrain,
            });
          });
        });
      }
    });
  };
  changeText = (textButton) => {
    this.setState({ textButton });
  };

  render() {
    if (this.state.isAmbassadeur) {
      return (
        <Fragment>
          <Breadcrumb title="Programme" parent="Dashboard" />
          <AmbassadeurComponent affiliateID={this.state.affiliateID} />
          <ParrainageComponent
            parrainageID={this.state.IDparrainage}
            avantageParrain={this.state.avantageParrain}
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Breadcrumb title="Programme" parent="Dashboard" />
          <ParrainageComponent
            parrainageID={this.state.IDparrainage}
            avantageParrain={this.state.avantageParrain}
          />
          {/* <div className="container row justify-content-center">
            <div className="card col-sm-12 col-xl-8">
              <div className="card-header">
                <h5>Programme Ambassadeur</h5>
              </div>
              <div className="card-body">
                <h6>
                  Il n'y a aucun programme ambassadeur actif pour votre compte.
                </h6>
              </div>
            </div>
          </div> */}
        </Fragment>
      );
    }
  }
}

export default PageProgramme;
