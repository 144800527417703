import React, { Component, Fragment } from "react";

import PopUpSub from "./popupSub";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PopUpUpgrade from "./popUpUpgrade";
import ListBenefits from "./listBenefit";

class SubscriptionCard extends Component {
  state = {
    name: this.props.product.name,
    price: this.props.product.prices[0],
    quantity: this.props.product.quantities[0],
    capital: this.props.product.capitals[0],
    botsDCA: this.props.product.DCA[0],
    idPaypal: this.props.product.idPaypal,
    showModal: false,
    uid: this.props.user,
  };

  // Afficher ou non le pop up
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  // Selection du niveau
  changeQuantity = (quantity) => {
    this.setState({
      price: this.props.product.prices[quantity - 1],
      quantity: this.props.product.quantities[quantity - 1],
      capital: this.props.product.capitals[quantity - 1],
    });
  };

  changeButton = () => {
    if (this.props.subscribed.activeAndNotCancel) {
    }
  };

  handlePayment = () => {
    this.setState({ showModal: !this.state.showModal });
    // Propagate payment to parent
    this.props.updatePage();
  };

  render() {
    const { name, quantities } = this.props.product;
    const priceInt = parseInt(this.state.price);
    const priceDec = parseInt(100 * (this.state.price - priceInt));

    return (
      <Fragment>
        <div className="card">
          {name === "Qwantic Gold" ? (
            <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-danger">
              <i className="icofont icofont-love"></i>
            </div>
          ) : (
            ""
          )}

          <div className="card-header">
            <div className="row justify-content-center">
              <h5>{name}</h5>
            </div>
            <span>
              <div className="row justify-content-center">{"Abonnement"}</div>
            </span>

            <div className="row justify-content-center m-t-25">
              <h1>
                <sup>€</sup>
                {priceInt}
                <sup>{priceDec === 0 ? "" : "." + priceDec}</sup>
                <sub>/mois</sub>
              </h1>
            </div>
          </div>

          {/* Bouton selection niveau d'abonnement */}

          <div className="card-body digits">
            <div className="row justify-content-center">
              {quantities.length === 1 ? (
                <div>
                  <span className="font-dark"> Offre Découverte</span>
                </div>
              ) : (
                quantities.map((quantity) => {
                  return (
                    <a
                      key={quantity}
                      className={
                        quantity === this.state.quantity
                          ? "badge badge-pill badge-primary"
                          : "badge badge-pill badge-light"
                      }
                      onClick={() => {
                        this.changeQuantity(quantity);
                      }}
                      href="#javascript"
                    >
                      {quantity}
                    </a>
                  );
                })
              )}
            </div>
          </div>

          {/* Fin bouton selection quantité */}
          <div className="m-10">
            {/* Changer le bouton selon abo actif ou non  */}

            <button
              className="btn btn-primary btn-lg btn-block"
              onClick={() => this.setState({ showModal: true })}
              disabled={
                this.props.subscribed.activeAndNotCancel &&
                this.state.name === this.props.subscribed.name &&
                this.state.quantity === this.props.subscribed.quantity
              }
            >
              {this.props.subscribed.activeAndNotCancel
                ? "Modifier"
                : "Choisir"}
            </button>
            <div className="m-10"></div>
            {/* Pop Up Update  */}
            <Modal
              isOpen={
                this.state.showModal && this.props.subscribed.activeAndNotCancel
              }
              toggle={this.toggle}
            >
              <ModalHeader toggle={this.toggle}>Update vers {name}</ModalHeader>
              <ModalBody>
                <PopUpUpgrade
                  value={{
                    name: this.state.name,
                    idPaypal: this.state.idPaypal,
                    UID: this.props.user,
                    price: this.state.price,
                    quantity: this.state.quantity,
                    subscriptionID: this.props.subscribed.subscriptionID,
                  }}
                  onPayment={this.handlePayment}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  {"Fermer"}
                </Button>
              </ModalFooter>
            </Modal>
            {/* Pop Up New subscription  */}
            <Modal
              isOpen={
                this.state.showModal &&
                !this.props.subscribed.activeAndNotCancel
              }
              toggle={this.toggle}
            >
              <ModalHeader toggle={this.toggle}>
                Souscription {name}
              </ModalHeader>
              <ModalBody>
                <PopUpSub
                  value={{
                    name: this.state.name,
                    idPaypal: this.state.idPaypal,
                    UID: this.props.user,
                    price: this.state.price,
                    quantity: this.state.quantity,
                  }}
                  onPayment={this.handlePayment}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  {"Fermer"}
                </Button>
              </ModalFooter>
            </Modal>
            <ListBenefits
              maxCapital={this.state.capital}
              botsDCA={this.state.botsDCA}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SubscriptionCard;
