import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";

class ParrainageComponent extends Component {
  state = { textButton: "Copier le code" };

  changeText = (textButton) => {
    this.setState({ textButton });
  };
  render() {
    return (
      <Fragment>
        <div className="container row justify-content-center">
          <div className="card col-sm-12 col-xl-8">
            <div className="card-header">
              <h5>Programme Parrainage</h5>
            </div>
            <div className="card-body">
              <h6>
                Augmentez votre capital en gestion avec le programme de
                parrainage !
              </h6>
              <span>
                Chaque utilisateur de Qwantic peut parrainer d'autres personnes
                et profiter d'un bonus sur son abonnement actuel. En partageant
                votre code parrainage vers un nouvel utilisateur, vous recevez
                un bonus de 10% d'augmentation de capital (maximum de 100%).
              </span>

              <div className="m-50"></div>
              <div className="col justify-content-center">
                <h6
                  className="font-primary f-s-italic o-visible"
                  style={{ textAlign: "center" }}
                >{`${this.props.parrainageID}`}</h6>
              </div>
              <div className="row justify-content-center">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(`${this.props.parrainageID}`);
                    this.changeText("Copié !");
                  }}
                  className="btn btn-outline-primary  btn-block col-xl-3 "
                >
                  {this.state.textButton}
                </button>
              </div>

              <div className="m-50"></div>
              <h6>
                Votre bonus actuel est de{" "}
                {parseInt(this.props.avantageParrain) > 20
                  ? "+100%"
                  : `+${parseInt(this.props.avantageParrain) * 5}%`}
              </h6>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ParrainageComponent;
