import React, { Fragment } from "react";

import FormAPI from "./formAPI";
import TableAPI from "./tableAPI";
import UsefullLinkAPI from "./usefulLinkAPI";
import Breadcrumb from "../../common/breadcrumb";
import PermissionAPI from "./permissionAPI";
class PageAPI extends React.Component {
  state = { update: 0 };

  handleUpdate = () => {
    setTimeout(() => {
      const update = this.state.update + 1;
      this.setState({ update });
    }, 100);
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb title="API Management" parent="Dashboard" />
        <div className="container">
          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12 col-xl-8">
                <FormAPI onSubmitAPI={this.handleUpdate} />
              </div>
              <div className="col-sm-12 col-xl-4">
                <UsefullLinkAPI />
              </div>

              <div className="col-sm-12">
                <TableAPI value={this.state.update} />
              </div>

              <div className="col-sm-12">
                <PermissionAPI />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PageAPI;
