import { firebase_app } from "../data/config";

export const getNotifications = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  const notifications = [];
  await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("notifications")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        notifications.push({ ...doc.data(), key: doc.id });
      });
    });
  return notifications;
};

export const deleteNotification = async (key) => {
  const uid = firebase_app.auth().currentUser.uid;

  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("notifications")
    .doc(key)
    .delete();

  return true;
};
