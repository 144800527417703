import React, { Fragment, useState } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import { isMobile } from "react-device-detect";
import { ToastContainer } from "react-toastify";
import Loader from "./common/loader";

const AppLayout = (props) => {
  const openCloseSidebar = () => {
    if (isMobile) {
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");

      // else {
      //   setSidebar(!sidebar);
      //   document.querySelector(".page-main-header").classList.add("open");
      //   document.querySelector(".page-sidebar").classList.add("open");
      // }
    }
  };

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <Sidebar />
          <RightSidebar />
          <div className="page-body" onClick={openCloseSidebar}>
            <div>{props.children}</div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
