import React, { Fragment } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import SweetAlert from "sweetalert2";
import Joi from "joi-browser";
import { postBotQwantic } from "../../../firebase-services/firebaseBotQwantic";
import { firebase_app } from "../../../data/config";

class FormListingBot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allocation: 50,

      error: false,
      capital: 0,
      updateFirebase: true,
    };
  }

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        var getWalletLoggedUser = firebase_app
          .functions()
          .httpsCallable("getWalletLoggedUser");

        getWalletLoggedUser({ UID: user.uid })
          .then((r) => {
            this.setState({
              capital: r.data.capital,
              updateFirebase: false,
              error: r.data.error,
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };

  schema = {
    name: Joi.string()
      .max(64)
      .allow("", null)
      .empty()
      .default("Qwantic Trading Bot"),
    allocation: Joi.number()
      .integer()
      .min(0)
      .max(100)
      .required()
      .label("Allocation"),
    leverage: Joi.number()
      .integer()
      .min(1)
      .max(2)
      .required()
      .label("API Secret"),
    mode: Joi.string().required().label("Mode"),
  };

  handleSubmitQwanticBot = (event) => {
    event.preventDefault();
    SweetAlert.fire({
      title: "Good job!",
      text: "You clicked the button!",
      icon: "info",
    });
    const { name, allocation, leverage, mode } = this.state;

    const result = Joi.validate(
      { name, allocation, leverage, mode },
      this.schema,
      { abortEarly: false }
    );
    if (result.error) {
      SweetAlert.fire({
        title: "Erreur !",
        text: "Veuillez vérifier vos données",
        icon: "error",
      });
    } else {
      SweetAlert.fire({
        title: "Good job!",
        text: "Vos données sont en cours d'envoie",
        icon: "info",
      });
      postBotQwantic({
        allocation: this.state.allocation,
        leverage: this.state.leverage,
        mode: this.state.mode,
        name: this.state.name === "" ? "Default Bot Trading" : this.state.name,
      });
    }
  };

  handleChangeAllocation = (event) => {
    this.setState({ allocation: event.target.value });
  };

  render() {
    let { name, allocation, leverage, mode, capital, error, updateFirebase } =
      this.state;
    return (
      <Fragment>
        <form
          className="theme-form mega-form"
          onSubmit={this.handleSubmitQwanticBot}
        >
          <div className="card">
            <div className="card-header">
              <h5>
                <i className="fa fa-cog fa-1x"></i>
                {"    Configuration New Listing Bot"}
              </h5>
            </div>

            <div className="card-body">
              <div className="form-group">
                <h6>Allocation par achat (USDT)</h6>
                {this.state.allocation < 10 && this.state.allocation !== "" ? (
                  <span className="text-danger">Minimum de 10 USDT</span>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">USDT</span>
                      </div>
                      <input
                        className="form-control"
                        type="namebot"
                        name="number"
                        value={allocation}
                        onChange={this.handleChangeAllocation}
                        onClick={() => {
                          this.setState({ allocation: "" });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h6>Nombre maximal de trade</h6>
                <div className="row">
                  <div className="col-12">
                    <Slider
                      min={0}
                      max={100}
                      step={1}
                      labels={{ 0: "0%", 100: "100%" }}
                      format={(e) => e + "%"}
                      value={0}
                      orientation="horizontal"
                      onChange={this.handleChangeReccurence}
                    />
                  </div>
                </div>
              </div>

              <div className="row ">
                <span className="col">
                  <strong>Balance minimale requise</strong>
                </span>
                <div>
                  {260 / leverage},00 <strong>USDT</strong>
                </div>
              </div>

              <div className="row ">
                <span className="col">
                  <strong>Capital disponible </strong>
                </span>
                <div>
                  {updateFirebase || error
                    ? "N/A"
                    : parseInt(100 * capital) / 100}{" "}
                  <strong>{" USDT"}</strong>
                </div>
              </div>
              <div className="row ">
                <span className="col">
                  <strong>Capital alloué</strong>
                </span>
                <div
                  className={
                    (capital * allocation * leverage) / 100 > 260 / leverage
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {updateFirebase || error
                    ? "N/A"
                    : parseInt(capital * allocation * leverage) / 100}{" "}
                  <strong>{" USDT"}</strong>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                  onClick={this.props.onSubmitBot}
                >
                  {"Soumettre"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default FormListingBot;
