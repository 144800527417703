import { firebase_app, FieldValue } from "../data/config";

// Function pour obtenir tous les APIS enregistrés sur Firebase
export const getAllSymbol = async () => {
  const symbols = [];
  await firebase_app
    .firestore()
    .collection("data")
    .doc("DCA")
    .collection("symbol")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        symbols.push({ ...doc.data() });
      });
    });
  return symbols;
};

export const postBotDCA = (value) => {
  const uid = firebase_app.auth().currentUser.uid;

  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("DCA")
    .set(value, { merge: true });
};

export const getAllBotsDCA = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  var bots = await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("DCA")
    .get();

  return bots.data();
};

export const deleteBotDCA = async (symbol) => {
  const uid = firebase_app.auth().currentUser.uid;

  var tempNamespace = {};

  tempNamespace[symbol] = FieldValue.delete();

  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("DCA")
    .update(tempNamespace);
};

export const deleteAllLogDCA = async (keys) => {
  const uid = firebase_app.auth().currentUser.uid;

  var tempNamespace = {};
  keys.forEach((key) => (tempNamespace[key] = FieldValue.delete()));

  return firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("DCALog")
    .update(tempNamespace)
    .then(() => {
      return true;
    });
};

export const getLogDCA = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  var logs = await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("bot")
    .doc("DCALog")
    .get();

  return logs.data();
};
