import {
  Home,
  Lock,
  ShoppingCart,
  Command,
  DollarSign,
  HelpCircle,
  Gift,
  Briefcase,
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",

    active: false,
    path: "/dashboard",
  },

  {
    title: "APIs",
    icon: Lock,
    type: "link",
    path: "/apimanagement",
    active: false,
  },
  {
    title: "Smart Bot",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        path: "/bot/dca",
        title: "DCA",
        type: "link",
      },
      // {
      //   path: "/bot/newlisting",
      //   title: "New Listing (beta)",
      //   type: "link",
      // },
      {
        path: "/bot/buyTheDipBot",
        title: "Buy The Dip (beta)",
        type: "link",
      },
    ],
  },
  {
    title: "Trading Bot",
    icon: Command,
    type: "sub",
    active: false,
    children: [
      {
        path: "/bot/qwantic-trading-bot",
        title: "Qwantic Trading Bot",
        type: "link",
      },
    ],
  },
  {
    title: "Smart Wallet",
    icon: Briefcase,
    type: "link",
    path: "/wallet",
    active: false,
  },
  {
    title: "Abonnement",
    icon: ShoppingCart,
    type: "link",
    path: "/subscription",
    active: false,
  },
  {
    title: "Programme",
    icon: Gift,
    type: "link",
    path: "/programme",
    active: false,
  },
  {
    title: "Faq",
    icon: HelpCircle,
    type: "link",
    path: "/faq",
    active: false,
  },
];
