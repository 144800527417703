import React, { Fragment } from "react";
import TableAPI from "../API/tableAPI";
import Breadcrumb from "../../common/breadcrumb";
import FormQwanticTradingBot from "./FormQwanticTradingBot";
import TableBot from "./tableBot";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

class QwanticTradingBot extends React.Component {
  state = { update: 0, isTourOpen: false };
  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);
  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  steps = [
    {
      selector: ".apikey",
      content: (
        <div>
          <h4>Step 1</h4>
          <p>
            Vérifiez que vous avez une clé API "Binance Futures" active sur
            Qwantic
          </p>
        </div>
      ),
    },

    {
      selector: ".formQwantic",
      content: (
        <div>
          <h4>Step 2</h4>
          <p>
            Paramétrez votre robot en choissisant levier et allocation.
            Attention à respecter le capital minimum recommandé pour une
            expérience maximale.
          </p>
        </div>
      ),
    },
    {
      selector: ".tableBot",
      content: (
        <div>
          <h4>Step 3</h4>
          <p>
            Suivez votre bot actuellement actif sur votre compte. En supprimant
            la ligne, le bot sera inactif.
          </p>
        </div>
      ),
    },
  ];
  handleUpdate = () => {
    setTimeout(() => {
      const update = this.state.update + 1;
      this.setState({ update });
    }, 100);
  };

  render() {
    return (
      <Fragment>
        <Tour
          steps={this.steps}
          isOpen={this.state.isTourOpen}
          rounded={2}
          onRequestClose={this.closeTour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
        />
        <Breadcrumb title="Qwantic Trading Bot" parent="Trading Bot" />
        <div className="container">
          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12">
                <button
                  className="btn btn-primary m-10"
                  onClick={() => this.setState({ isTourOpen: true })}
                >
                  {"Besoin d'aide ?"}
                </button>
                <div className="apikey">
                  <TableAPI
                    className="detail"
                    value={null}
                    mandatoryExchange={"Binance Futures"}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-xl-5">
                <div className="formQwantic">
                  <FormQwanticTradingBot onSubmitBot={this.handleUpdate} />
                </div>
              </div>
              <div className="col-sm-12 col-xl-7">
                <div className="tableBot">
                  <TableBot value={this.state.update} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default QwanticTradingBot;
