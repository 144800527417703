import React, { Fragment } from "react";
import { postAPI } from "../../../firebase-services/firebaseAPI";
import Joi from "joi-browser";
import SweetAlert from "sweetalert2";
import { firebase_app } from "../../../data/config";

// TODO : Add verification API before submit

class FormAPI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      myAPIKey: "",
      mySecretKey: "",
      exchange: "Binance Futures",
      path: "binanceFutures",
    };
    this.handleChangeKey = this.handleChangeKey.bind(this);
    this.handleChangeExchange = this.handleChangeExchange.bind(this);
    this.handleChangeSecret = this.handleChangeSecret.bind(this);
    this.handleSubmitAPI = this.handleSubmitAPI.bind(this);
  }
  schema = {
    myAPIKey: Joi.string().max(64).required().label("API Key"),
    mySecretKey: Joi.string().max(64).required().label("API Secret"),
    exchange: Joi.string().required(),
    path: Joi.string().required(),
  };
  handleChangeExchange(event) {
    const schemaBinance = {
      myAPIKey: Joi.string().min(64).max(64).required().label("API Key"),
      mySecretKey: Joi.string().min(64).max(64).required().label("API Secret"),
      exchange: Joi.string().required(),
      path: Joi.string().required(),
    };
    const schemaFTX = {
      myAPIKey: Joi.string().required().label("API Key"),
      mySecretKey: Joi.string().required().label("API Secret"),
      exchange: Joi.string().required(),
      path: Joi.string().required(),
    };
    const schemaByBit = {
      myAPIKey: Joi.string().max(64).required().label("API Key"),
      mySecretKey: Joi.string().max(64).required().label("API Secret"),
      exchange: Joi.string().required(),
      path: Joi.string().required(),
    };
    if (event.target.value === "Binance Futures USDT-M") {
      this.setState({
        path: "binanceFutures",
        exchange: "Binance Futures",
      });
    }
    if (event.target.value === "Binance Spot") {
      this.setState({
        path: "binanceSpot",
        exchange: "Binance Spot",
      });
    }
    if (event.target.value === "ByBit") {
      this.setState({
        path: "bybit",
        exchange: "ByBit",
      });
    }
    if (event.target.value === "FTX") {
      console.log("FTX");
      this.setState({ path: "ftx", exchange: "FTX" });
    }
  }
  handleChangeKey(event) {
    this.setState({ myAPIKey: event.target.value });
  }

  handleChangeSecret(event) {
    this.setState({ mySecretKey: event.target.value });
  }

  handleSubmitAPI(event) {
    event.preventDefault();

    SweetAlert.fire({
      title: "Info",
      text: "Vos données sont en cours d'envoie...",
      icon: "info",
    });

    const result = Joi.validate(this.state, this.schema, {
      abortEarly: false,
    });

    if (result.error) {
      console.log(result);
      SweetAlert.fire({
        title: "Erreur !",
        text: "Vérifiez le format de la clé API",
        icon: "error",
      });
    } else {
      // Test Clé Binance
      const controlAPIKeyBinance = firebase_app
        .functions()
        .httpsCallable("controlAPIKeyBinance");

      // Appel de la fonction de vérification
      controlAPIKeyBinance({
        api_key: this.state.myAPIKey,
        api_secret: this.state.mySecretKey,
        typeKey: this.state.path,
      })
        .then((res) => {
          if (res.data) {
            // Si aucune erreur de format, check si la clé est valide via Binance
            SweetAlert.fire({
              title: "Terminé",
              text: "Vos données sont valides",
              icon: "success",
            });
            postAPI({
              myAPIKey: this.state.myAPIKey,
              mySecretKey: this.state.mySecretKey,
              exchange: this.state.exchange,
              path: this.state.path,
            });
          } else {
            SweetAlert.fire({
              title: "Erreur !",
              text: "Vérifiez le format ou les accès de la clé API ",
              icon: "error",
            });
          }
        })
        .catch((e) => console.log(e));
    }
  }
  render() {
    return (
      <Fragment>
        <form className="theme-form mega-form" onSubmit={this.handleSubmitAPI}>
          <div className="card">
            <div className="card-header">
              <h5>+ Connecter une nouvelle Clé API</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <h6>Nom de la plateforme</h6>

                <select
                  className="form-control digits"
                  id="exampleFormControlSelect9"
                  defaultValue="Binance Futures USDT-M"
                  onChange={this.handleChangeExchange}
                >
                  <option> {"Binance Futures USDT-M"}</option>
                  <option>{"Binance Spot"}</option>
                  <option>{"FTX"}</option>
                  <option>{"ByBit"}</option>
                </select>
              </div>
              <div className="form-group">
                <h6>Clé API</h6>

                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter API Key"
                  value={this.state.myAPIKey}
                  onChange={this.handleChangeKey}
                />
              </div>
              <div className="form-group">
                <h6>Clé secrète</h6>

                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter API Secret"
                  value={this.state.mySecretKey}
                  onChange={this.handleChangeSecret}
                />
              </div>
            </div>
            <div className="card-footer">
              <div className="row justify-content-center ">
                <button
                  className="btn btn-primary btn-lg btn-block mr-1 col-5"
                  type="submit"
                  onClick={this.props.onSubmitAPI}
                >
                  {"Soumettre"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default FormAPI;
