import React, { Fragment } from "react";
import logo from "../assets/images/qwantic-logo-dark.png";
import { useHistory } from "react-router-dom";
import { firebase_app } from "../data/config";
import SweetAlert from "sweetalert2";

const ResetPwd = () => {
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email } = e.target.elements;

    if (email.value) {
      firebase_app
        .auth()
        .sendPasswordResetEmail(email.value)
        .then(history.push("/login"));
    } else {
      SweetAlert.fire({
        title: "Erreur !",
        text: "Veuillez renseigner une adresse e-mail valide",
        icon: "error",
      });
    }
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- Reset Password page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4 p-4">
                      <h4 className="text-center">
                        {"Mot de passe oublié ? "}
                      </h4>
                      <form className="theme-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label for="email" className="col-form-label">
                            {"Email"}
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="email@email.com"
                          />
                        </div>

                        <div className="form-group form-row mb-0">
                          <div className="col-md-2">
                            <button className="btn btn-primary" type="submit">
                              {"Envoyer e-mail"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reset Password page end--> */}
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPwd;
