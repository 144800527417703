import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import DefinitionListing from "./DefinitionListing";
import TableAPI from "./../API/tableAPI";
import FormListingBot from "./FormListingBot";

class ListingPage extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <Breadcrumb title="New Listing Bot (Coming Soon)" parent="Smart Bot" />
        <div className="container">
          <div className="col-sm-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12">
                <DefinitionListing />
              </div>

              <div className="col-sm-12 col-xl-5">
                {/* <FormListingBot /> */}
              </div>
              <div className="col-sm-7">
                <TableAPI value={null} mandatoryExchange={"Binance Spot"} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ListingPage;
